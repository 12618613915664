import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { ProductKindImageView } from './ResourceImage';
import {
  ProductKindSearchQuery,
  ProductKindReadQuery,
  ProductKindCreateMutation,
  ProductKindUpdateMutation,
  ProductKindNameQuery,
} from './query';

export const ProductKindResource = Resource({
  resourceId: 'product_kind',
  app: 'ecom',
  name: 'Product Kind',
  keyVal: 'ProductKind',
  topicName: 'ProductKind',
  storeId: 'ProductKind',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Kind',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<ProductKindImageView item={item} />),
  queries: {
    read: QueryAction({
      query: ProductKindReadQuery,
      resourceNamePath: 'item.kind.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: ProductKindSearchQuery, responsePath: 'ecom_product_kind_search' }),
    search: QueryAction({
      query: ProductKindSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'kind.name',
    }),
    name: QueryAction({ query: ProductKindNameQuery, resourceNamePath: 'ecom_product_kind_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: ProductKindUpdateMutation, cs: ['kind'] }),
    create: MutationAction({ mutation: ProductKindCreateMutation }),
  },
});
