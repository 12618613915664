import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const CmsImageCreateHintQuery = gql`
query CmsImageCreateHintQuery($name: String!) {
  cms_image_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        name
      }
    }
    key_suggestions
  }
}
`;

export const CmsImageCreateMutation = gql`
mutation CmsImageCreate($image: CmsImageImageInput!, $key: String) {
  cms_image_create(image: $image, key: $key) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    signed {
      signed_data
      url
      url_suffix
    }
  }
}
`;

// const VariationMetaFields = `
// version
// store_path
// width
// height
// size
// format
// variation_key
// `;

export const CmsImageReadQuery = gql`
query CmsImageReadQuery($resource: CmsImageInput!, $withHistory: Boolean) {
  cms_image_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name { filename, store_path }
      image {
        filename
        store_path
        alt
        desc
        cr_note
        version
      }
      computed {
        total_size
      }
      status {
        uploaded_at
        archived
        # variations_status
        # variations_status {
        #   key
        #   status
        #   version
        #   error
        #   variation {
        #     key
        #     width
        #     height
        #     quality
        #     format
        #   }
        # }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const CmsImageUpdateMutation = gql`
mutation CmsImageUpdateMutation(
  $resource: CmsImageInput!,
  $image: CmsImageImageInput!,
  $version: RecordStage) {
  cms_image_update(
    resource: $resource,
    image: $image,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const CmsImageDeleteMutation = gql`
mutation  CmsImageDeleteMutation(
  $resource: CmsImageInput!,
  $name: String!) {
  cms_image_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const CmsImageSearchQuery = gql`
query CmsImageSearchQuery($search: String!, $page: PagedRequestInput) {
  cms_image_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        filename
        store_path
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const CmsImageNameQuery = gql`
query CmsImageNameQuery($resource: CmsImageInput!) {
  cms_image_name(resource: $resource) {
    name
  }
}
`;
