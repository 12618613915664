import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const ProductSpecGroupCreateHintQuery = gql`
query ProductSpecGroupCreateHintQuery($name: String!) {
  ecom_product_specgroup_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      specgroup {
        name
        internal_name
      }
    }
    key_suggestions
  }
}
`;

export const ProductSpecGroupCreateMutation = gql`
mutation ProductSpecGroupCreate(
  $specgroup: ProductSpecGroupSpecGroupInput!,
  $key: String,
) {
  ecom_product_specgroup_create(
    specgroup: $specgroup,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const ProductSpecGroupReadQuery = gql`
query ProductSpecGroupReadQuery($resource: ProductSpecGroupInput!, $withHistory: Boolean) {
  ecom_product_specgroup_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      specgroup {
        name
        internal_name
        specs { hashkey: _hashkey }
        channel
        default_values
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const ProductSpecGroupUpdateMutation = gql`
mutation ProductSpecGroupUpdateMutation(
  $resource: ProductSpecGroupInput!,
  $specgroup: ProductSpecGroupSpecGroupInput!,
  $version: RecordStage) {
  ecom_product_specgroup_update(
    resource: $resource,
    specgroup: $specgroup,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const ProductSpecGroupDeleteMutation = gql`
mutation  ProductSpecGroupDeleteMutation(
  $resource: ProductSpecGroupInput!,
  $name: String!) {
  ecom_product_specgroup_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const ProductSpecGroupSearchQuery = gql`
query ProductSpecGroupSearchQuery($search: String!, $page: PagedRequestInput) {
  ecom_product_specgroup_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      specgroup {
        name
        internal_name
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const ProductSpecGroupNameQuery = gql`
query ProductSpecGroupNameQuery($resource: ProductSpecGroupInput!) {
  ecom_product_specgroup_name(resource: $resource) {
    name
    internal_name
  }
}
`;
