import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const ProductKindCreateHintQuery = gql`
query ProductKindCreateHintQuery($name: String!) {
  ecom_product_kind_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      kind {
        name
        internal_name
      }
    }
    key_suggestions
  }
}
`;

export const ProductKindCreateMutation = gql`
mutation ProductKindCreate(
  $kind: ProductKindKindInput!,
  $key: String,
) {
  ecom_product_kind_create(
    kind: $kind,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const ProductKindReadQuery = gql`
query ProductKindReadQuery($resource: ProductKindInput!, $withHistory: Boolean) {
  ecom_product_kind_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      kind {
        name
        internal_name
        spec_groups { hashkey: _hashkey }
        default_values
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const ProductKindUpdateMutation = gql`
mutation ProductKindUpdateMutation(
  $resource: ProductKindInput!,
  $kind: ProductKindKindInput!,
  $version: RecordStage) {
  ecom_product_kind_update(
    resource: $resource,
    kind: $kind,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const ProductKindDeleteMutation = gql`
mutation  ProductKindDeleteMutation(
  $resource: ProductKindInput!,
  $name: String!) {
  ecom_product_kind_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const ProductKindSearchQuery = gql`
query ProductKindSearchQuery($search: String!, $page: PagedRequestInput) {
  ecom_product_kind_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      kind {
        name
        internal_name
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const ProductKindNameQuery = gql`
query ProductKindNameQuery($resource: ProductKindInput!) {
  ecom_product_kind_name(resource: $resource) {
    name
    internal_name
  }
}
`;
