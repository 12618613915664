import { WebsiteResource } from './website/resource';
import { WebTreeResource } from './webtree/resource';
import { CmsPageResource } from './page/resource';
import { CmsStructureResource } from './structure/resource';
import { CmsImageResource } from './image/resource';
import { CmsLinkListResource } from './linklist/resource';
import { CmsStructureContentResource } from './structureContent/resource';
import { CmsAssetResource } from './asset/resource';

export const Resources = {
  Website: WebsiteResource,
  WebTree: WebTreeResource,
  CmsPage: CmsPageResource,
  CmsStructure: CmsStructureResource,
  CmsImage: CmsImageResource,
  CmsLinkList: CmsLinkListResource,
  CmsStructureContent: CmsStructureContentResource,
  CmsAsset: CmsAssetResource,
};

export function getCmsResourceDef(id) {
  return Resources[id] ?? null;
}
