import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { ProductSpecImageView } from './ResourceImage';
import {
  ProductSpecSearchQuery,
  ProductSpecReadQuery,
  ProductSpecCreateMutation,
  ProductSpecUpdateMutation,
  ProductSpecNameQuery,
} from './query';

export const ProductSpecResource = Resource({
  resourceId: 'product_spec',
  app: 'ecom',
  name: 'Product Spec',
  keyVal: 'ProductSpec',
  topicName: 'ProductSpec',
  storeId: 'ProductSpec',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Spec',
      shape: Yup.object().shape({
        label: StringFieldMinMax(3, 120),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<ProductSpecImageView item={item} />),
  queries: {
    read: QueryAction({
      query: ProductSpecReadQuery,
      resourceNamePath: 'item.spec.label',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: ProductSpecSearchQuery, responsePath: 'ecom_product_spec_search' }),
    search: QueryAction({
      query: ProductSpecSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'spec.name',
    }),
    name: QueryAction({ query: ProductSpecNameQuery, resourceNamePath: 'ecom_product_spec_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: ProductSpecUpdateMutation, cs: ['spec'] }),
    create: MutationAction({ mutation: ProductSpecCreateMutation }),
  },
});
