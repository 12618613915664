/**
 * For python object ContentSourceValue.Item
 */
export function contentSourceValueItemForSave(obj) {
  // console.log('contentSourceValueItemForSave', JSON.parse(JSON.stringify(obj)));
  if (!obj) return null;

  let newObj = {
    ...obj,
  };

  if (!obj.value) return obj;

  if (obj.value.structure) {
    let contentKey = null;
    let data = null;
    if (obj.value.structure.content_key) {
      if (obj.value.structure.content_key.hashkey) {
        contentKey = {
          _hashkey: obj.value.structure.content_key.hashkey,
        };
      } else if (obj.value.structure.content_key.account && obj.value.structure.content_key.key) {
        contentKey = {
          ...obj.value.structure.content_key,
        };
      }
    } else {
      // eslint-disable-next-line no-use-before-define
      data = contentItemDataForSave(obj.value.structure.data);
    }
    newObj = {
      ...newObj,
      typ: 'Structure',
      value: {
        ...(newObj.value || {}),
        action: null,
        structure: {
          content_key: contentKey,
          data,
        },
      },
      action_key: null,
    };
  } else if (obj.value.action) {
    newObj = {
      ...newObj,
      typ: 'Action',
      structure: null,
      src_structure: null,
      value: {
        ...(newObj.value || {}),
        structure: null,
      },
    };
  }

  return newObj;
}

export function contentItemDataForSave(obj) {
  const newObj = {
    ...obj,
  };

  Object.keys(newObj).forEach((groupKey) => {
    const props = newObj[groupKey];
    if (props && typeof props === 'object') {
      Object.keys(props).forEach((key) => {
        const val = props[key];
        if (val && typeof val === 'object' && val.data && val.data.value) {
          newObj[groupKey][key] = {
            ...val,
            data: {
              ...contentSourceValueItemForSave(val.data),
            },
          };
        }
        if (val && typeof val === 'object' && Object.keys(val).length > 0) {
          Object.keys(val).forEach((innerKey) => {
            const innerObj = val[innerKey];
            if (typeof innerObj === 'object' && innerObj?.data) {
              newObj[groupKey][key][innerKey] = {
                ...innerObj,
                data: contentSourceValueItemForSave(innerObj.data),
              };
            }
          });
        }
      });
    }
  });

  return newObj;
}
