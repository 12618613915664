import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
  // RefField,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import { WebsiteImageView } from './ResourceImage';
import {
  WebsiteSearchQuery,
  WebsiteReadQuery,
  WebsiteCreateMutation,
  WebsiteUpdateMutation,
  WebsiteNameQuery,
} from './query';

export const WebsiteResource = Resource({
  resourceId: 'website',
  resourceKeys: ['website'],
  app: 'cms',
  name: 'Website',
  keyVal: 'Website',
  topicName: 'Website',
  storeId: 'Website',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Website',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        variables: Yup.array().of(Yup.object().shape({
          key: StringFieldMinMax(1, 120),
          value: StringFieldMinMax(1, 256),
        })).nullable().default([]),
      }),

    }),
    // ColumnSet({
    //   name: 'Desc',
    //   shape: Yup.object().shape({
    //     desc: Yup.string().max(1024, 'Too big').nullable().default(''),
    //     image: Yup.string(),
    //   }),
    // }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<WebsiteImageView item={item} />),
  queries: {
    read: QueryAction({
      query: WebsiteReadQuery,
      resourceNamePath: 'item.website.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: WebsiteSearchQuery, responsePath: 'cms_website_search' }),
    search: QueryAction({
      query: WebsiteSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.name',
    }),
    name: QueryAction({ query: WebsiteNameQuery, resourceNamePath: 'cms_website_name.name' }),
  },
  mutations: {
    update: MutationAction({
      mutation: WebsiteUpdateMutation,
      cs: ['website'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        if (!resp.website.variables) {
          resp.website.variables = [];
        }
        resp.website.variables = arrayAsObject(
          resp.website.variables,
        );
        // console.log('> Prepare for edit', JSON.stringify(resp));
        return resp;
      },
      prepareForSave(self, resource, data) {
        // console.log('Prepare for Save', resource, JSON.stringify(data));
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.website.variables = objectAsArray(resp.website.variables);
        resp.website.options = [];
        return resp;
      },
    }),
    create: MutationAction({ mutation: WebsiteCreateMutation }),
  },
});
