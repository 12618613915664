import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { HomePage } from 'sports/Home';
import { AppContextConsumer, AppContextProvider } from 'core/AppContext';
import { AppLayout } from 'core/AppLayout';
import { PrivateContent, PrivateAccountContent } from 'core/PrivateContent';
import { TopMenuBar } from 'core/TopBar';
import { BottomBar } from 'core/BottomBar';
import { UserContextProvider } from 'core/UserContext';
import { SportsRoutes } from 'sports/routes';
import { EcomRoutes } from 'ecom/routes';
import { CmsRoutes } from 'cms/routes';
import { FantasyEditorRoutes } from 'Fantasy/fantasyCreditEditor/routes';
import { AccountRoutes } from 'account/routes';
import { UserSettingsRoutes } from 'account/UserSettings/routes';
import { AdminSettingsRoutes } from 'admin/routes';
import { PagesRoutes } from 'pages/routes';
import { Pages } from 'core/pages';
import { Theme } from 'style/Theme';
import { RealtimeContextProvider } from 'ants/RealtimeContext';
import { ErrorBoundary } from 'core/ErrorBoundary';
import { Config } from 'config';
import { RealtimeConnectionStatus } from 'ants/ConnectionStatusView';
import { FantasyRoutes } from 'Fantasy/routes';

const DevPage = lazy(() => import('dev/dev'));
const Pricing = lazy(() => import('./account/Pricing'));
const WizardDashboardController = lazy(() => import('./sports/cricket/Wizard/WizardController'));
const AllRecentActivity = lazy(() => import('core/AllRecentActivity'));

function App() {
  return (
    <AppContextProvider value={{}}>
      <UserContextProvider value={{}}>
        <RealtimeContextProvider value={{}}>
          <AppContextConsumer>
            {({ viewState }) => (
              <Theme name={Config.defaultTheme}>
                <AppLayout
                  top={(<TopMenuBar />)}
                  state={viewState}
                  bottom={(<BottomBar />)}
                >
                  <div>
                    <ErrorBoundary>
                      <Routes>
                        <Route path="pages/*" element={<PagesRoutes />} />
                        <Route
                          path="/pricing"
                          element={(
                            <Suspense fallback={<>...</>}>
                              <Pricing />
                            </Suspense>
                        )}
                        />
                        <Route
                          path={Pages.sports.meta.starPath}
                          element={<PrivateAccountContent><SportsRoutes /></PrivateAccountContent>}
                        />
                        <Route
                          path={Pages.ecom.meta.starPath}
                          element={<PrivateAccountContent><EcomRoutes /></PrivateAccountContent>}
                        />
                        <Route
                          path={Pages.cms.meta.starPath}
                          element={<PrivateAccountContent><CmsRoutes /></PrivateAccountContent>}
                        />
                        <Route
                          path={Pages.account.meta.starPath}
                          element={<PrivateContent><AccountRoutes /></PrivateContent>}
                        />
                        <Route
                          path={Pages.user.meta.starPath}
                          element={<PrivateContent><UserSettingsRoutes /></PrivateContent>}
                        />
                        <Route
                          path={Pages.admin.meta.starPath}
                          element={<PrivateContent><AdminSettingsRoutes /></PrivateContent>}
                        />
                        <Route
                          path={Pages.dev.meta.starPath}
                          element={(
                            <PrivateContent>
                              <Suspense>
                                <DevPage />
                              </Suspense>
                            </PrivateContent>
                          )}
                        />
                        <Route
                          path={Pages.match.meta.starPath}
                          element={(
                            <PrivateAccountContent>
                              <FantasyEditorRoutes />
                            </PrivateAccountContent>
                          )}
                        />
                        <Route
                          path={Pages.fantasy.meta.starPath}
                          element={(
                            <PrivateAccountContent>
                              <FantasyRoutes />
                            </PrivateAccountContent>
                          )}
                        />
                        <Route
                          index
                          element={<PrivateAccountContent><HomePage /></PrivateAccountContent>}
                        />
                        <Route
                          path="/cricket/wizard"
                          element={(
                            <Suspense fallback={<>...</>}>
                              <WizardDashboardController />
                            </Suspense>
                          )}
                        />
                        <Route
                          path="/all-activity/:topic"
                          element={(<Suspense fallback={<>...</>}><AllRecentActivity /></Suspense>)}
                        />
                        <Route
                          path="*"
                          element={(
                            <main style={{ padding: '1rem' }}>
                              <p>Found no pages!</p>
                            </main>
                        )}
                        />
                      </Routes>
                      <RealtimeConnectionStatus />
                    </ErrorBoundary>
                  </div>
                </AppLayout>
              </Theme>
            )}
          </AppContextConsumer>
        </RealtimeContextProvider>
      </UserContextProvider>
    </AppContextProvider>
  );
}

export default App;
