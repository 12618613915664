import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const ProductCategoryCreateHintQuery = gql`
query ProductCategoryCreateHintQuery($name: String!) {
  ecom_category_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      category {
        name
        internal_name
        slug
        parent { hashkey: _hashkey }
      }
    }
    key_suggestions
  }
}
`;

export const ProductCategoryCreateMutation = gql`
mutation ProductCategoryCreate(
  $category: ProductCategoryCategoryInput!,
  $key: String,
) {
  ecom_category_create(
    category: $category,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const ProductCategoryReadQuery = gql`
query ProductCategoryReadQuery($resource: ProductCategoryInput!, $withHistory: Boolean) {
  ecom_category_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      category {
        name
        internal_name
        slug
        parent { hashkey: _hashkey }
      }
      desc {
        desc
        image
      }
      access { mode, owner, group }
      source { sources { name, src_id, src_id } }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const ProductCategoryUpdateMutation = gql`
mutation ProductCategoryUpdateMutation(
  $resource: ProductCategoryInput!,
  $category: ProductCategoryCategoryInput!,
  $desc: ProductCategoryDescInput!,
  $access: ProductCategoryAccessInput!,
  # $source: ProductCategorySourceInput!,
  $version: RecordStage) {
  ecom_category_update(
    resource: $resource,
    category: $category,
    desc: $desc,
    access: $access,
    # source: $source,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const ProductCategoryDeleteMutation = gql`
mutation  ProductCategoryDeleteMutation(
  $resource: ProductCategoryInput!,
  $name: String!) {
  ecom_category_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const ProductCategorySearchQuery = gql`
query ProductCategorySearchQuery($search: String!, $page: PagedRequestInput) {
  ecom_category_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      category {
        name
        internal_name
        slug
        parent { hashkey: _hashkey }
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const ProductCategoryNameQuery = gql`
query ProductCategoryNameQuery($resource: ProductCategoryInput!) {
  ecom_category_name(resource: $resource) {
    name
    internal_name
    slug
    parent { hashkey: _hashkey }
  }
}
`;
