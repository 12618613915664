import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
  RefField,
} from 'ants/resource';
import { ProductCategoryImageView } from './ResourceImage';
import {
  ProductCategorySearchQuery,
  ProductCategoryReadQuery,
  ProductCategoryCreateMutation,
  ProductCategoryUpdateMutation,
  ProductCategoryNameQuery,
} from './query';

export const ProductCategoryResource = Resource({
  resourceId: 'category',
  app: 'ecom',
  name: 'Product Category',
  keyVal: 'ProductCategory',
  topicName: 'ProductCategory',
  storeId: 'ProductCategory',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Category',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        slug: StringFieldMinMax(3, 120),
        parent: RefField(),
      }),
      referenceFields: ['parent'],
    }),
    ColumnSet({
      name: 'Desc',
      shape: Yup.object().shape({
        desc: Yup.string().max(1024, 'Too big').nullable().default(''),
        image: Yup.string(),
      }),
    }),
    ColumnSet({
      name: 'Access',
      shape: Yup.object().shape({
        mode: Yup.number(),
        owner: Yup.string(),
        group: Yup.string(),
      }),
    }),
    // ColumnSet({
    //   name: 'Source',
    //   shape: Yup.object().shape({
    //   }),
    // }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<ProductCategoryImageView item={item} />),
  queries: {
    read: QueryAction({
      query: ProductCategoryReadQuery,
      resourceNamePath: 'item.category.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: ProductCategorySearchQuery, responsePath: 'ecom_category_search' }),
    search: QueryAction({
      query: ProductCategorySearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'category.name',
    }),
    name: QueryAction({ query: ProductCategoryNameQuery, resourceNamePath: 'ecom_category_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: ProductCategoryUpdateMutation, cs: ['category', 'desc', 'access'] }),
    create: MutationAction({ mutation: ProductCategoryCreateMutation }),
  },
});
