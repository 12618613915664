import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const ProductCreateHintQuery = gql`
query ProductCreateHintQuery($name: String!) {
  ecom_product_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        name
        internal_name
      }
    }
    key_suggestions
  }
}
`;

export const ProductCreateMutation = gql`
mutation ProductCreate(
  $name: ProductNameInput!,
  $key: String,
) {
  ecom_product_create(
    name: $name,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const ProductReadQuery = gql`
query ProductReadQuery($resource: ProductInput!, $withHistory: Boolean) {
  ecom_product_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        name
        internal_name
      }
      product {
        slug
        sku
      }

      source { sources { name, src_id, src_id } }
      media { media { typ, display_mode, value, title, order_index, alt } }
      spec { kind { hashkey: _hashkey }, specs }
      access { mode, owner, group }

      tags {
        primary_category { hashkey: _hashkey }
        other_categories { hashkey: _hashkey }
        tags
      }

      manf { manufacturer, brand, assembled_in }
      inventory { enable, locations { hashkey: _hashkey } }

      price {
        unit
        per_unit
        includes_tax
        tax_group { hashkey: _hashkey }
        rules { 
          typ
          discount_prec
          discount_amount
          new_unit_price
          original_unit_price
          valid_from
          valid_to
          priority_index
          assign_to
          label
          min_unit
          max_unit
          allow_line_discount
          allow_order_discount
          website { hashkey: _hashkey }
        }
      }

      related {
        kind
        use_base_price
        items {
          product
          unit
          order_index
          spec { hashkey: _hashkey }
          spec_value
        }
        configs {
          key
          name
          items {
            product
            unit
            order_index
            spec { hashkey: _hashkey }
            spec_value
          }
          min_items
          max_items
          order_index
        }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const ProductUpdateMutation = gql`
mutation ProductUpdateMutation(
  $resource: ProductInput!,
  $name: ProductNameInput!,
  $product: ProductProductInput!,
  $version: RecordStage) {
  ecom_product_update(
    resource: $resource,
    name: $name,
    product: $product,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const ProductDeleteMutation = gql`
mutation  ProductDeleteMutation(
  $resource: ProductInput!,
  $name: String!) {
  ecom_product_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const ProductSearchQuery = gql`
query ProductSearchQuery($search: String!, $page: PagedRequestInput) {
  ecom_product_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        name
        internal_name
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const ProductNameQuery = gql`
query ProductNameQuery($resource: ProductInput!) {
  ecom_product_name(resource: $resource) {
    name
    internal_name
  }
}
`;
