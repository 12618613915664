import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getAccountKey } from 'core/localStore';
import {
  useParams,
} from 'react-router-dom';
import {
  useQuery,
} from '@apollo/client';
import { AppScreenError, AppScreenLoading } from 'core/Screen';
import { StatusError, StatusLoading } from 'core/Status';
import { CmsStructureReadQuery } from '../structure/query';

const defaultContext = {
  sc: null,
  structure: null,
};

export const SCContext = React.createContext({ ...defaultContext });
export const SCContextConsumer = SCContext.Consumer;

export function SCContextProvider({
  value, children, structureKey, structurePlainKey,
  onChangeValue,
}) {
  const params = useParams();
  const [scContext, setSCContext] = useState({
    setSC(sc) {
      setSCContext((state) => ({
        ...state,
        sc,
      }));
    },
    setStructure(structure) {
      setSCContext((state) => ({
        ...state,
        sc: structure.resource.key,
        structure,
      }));
    },
    ...defaultContext,
    ...value,
  });

  // if (params.sc && (!scContext.sc || scContext.sc !== params.sc)) {
  //   scContext.setSC(params.sc);
  // }

  const variables = structureKey
    ? { _hashkey: structureKey }
    : {
      account: getAccountKey(),
      key: params.sc || structurePlainKey,
    };
  const { loading, error, data } = useQuery(
    CmsStructureReadQuery,
    { variables: { resource: variables } },
  );

  useEffect(() => {
    if (data && data.cms_structure_read) {
      if (onChangeValue) {
        onChangeValue(structureKey);
      }
      // console.log('data.cms_structure_read', data.cms_structure_read.item.structure);
      scContext.setStructure({ ...data.cms_structure_read.item });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading && !scContext.structure) {
    return (
      structureKey ? <StatusLoading /> : <AppScreenLoading />
    );
  }
  if (error && !data) {
    return (
      structureKey
        ? <StatusError error={error} />
        : <AppScreenError error={error} />
    );
  }

  if (!scContext.structure) {
    return (
      structureKey ? <StatusLoading /> : <AppScreenLoading />
    );
  }

  // if (data && data.cms_structure_read) {
  //   scContext.setStructure(data.cms_structure_read.item);
  // }

  return (
    <SCContext.Provider value={scContext}>
      {children}
    </SCContext.Provider>
  );
}

SCContextProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.element,
  structureKey: PropTypes.string,
  structurePlainKey: PropTypes.string,
  onChangeValue: PropTypes.func,
};
