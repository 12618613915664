import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const GlobalPriceRuleCreateHintQuery = gql`
query GlobalPriceRuleCreateHintQuery($name: String!) {
  ecom_price_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      rule {
        name
        internal_name
      }
    }
    key_suggestions
  }
}
`;

export const GlobalPriceRuleCreateMutation = gql`
mutation GlobalPriceRuleCreate(
  $rule: GlobalPriceRuleRuleInput!,
  $key: String,
) {
  ecom_price_create(
    rule: $rule,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const GlobalPriceRuleReadQuery = gql`
query GlobalPriceRuleReadQuery($resource: GlobalPriceRuleInput!, $withHistory: Boolean) {
  ecom_price_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      rule {
        name
        internal_name
        max_units
        max_units_per_user
        min_cart_total
        max_cart_total
        include_tax

        rule {
          typ
          discount_prec
          discount_amount
          new_unit_price
          original_unit_price
          valid_from
          valid_to
          priority_index
          assign_to
          label
          min_unit
          max_unit
          allow_line_discount
          allow_order_discount
          website { hashkey: _hashkey }
        }
      }

    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const GlobalPriceRuleUpdateMutation = gql`
mutation GlobalPriceRuleUpdateMutation(
  $resource: GlobalPriceRuleInput!,
  $rule: GlobalPriceRuleRuleInput!,
  $version: RecordStage) {
  ecom_price_update(
    resource: $resource,
    rule: $rule,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const GlobalPriceRuleDeleteMutation = gql`
mutation  GlobalPriceRuleDeleteMutation(
  $resource: GlobalPriceRuleInput!,
  $name: String!) {
  ecom_price_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const GlobalPriceRuleSearchQuery = gql`
query GlobalPriceRuleSearchQuery($search: String!, $page: PagedRequestInput) {
  ecom_price_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      rule {
        name
        internal_name
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const GlobalPriceRuleNameQuery = gql`
query GlobalPriceRuleNameQuery($resource: GlobalPriceRuleInput!) {
  ecom_price_name(resource: $resource) {
    name
    internal_name
  }
}
`;
