import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { ProductSpecGroupImageView } from './ResourceImage';
import {
  ProductSpecGroupSearchQuery,
  ProductSpecGroupReadQuery,
  ProductSpecGroupCreateMutation,
  ProductSpecGroupUpdateMutation,
  ProductSpecGroupNameQuery,
} from './query';

export const ProductSpecGroupResource = Resource({
  resourceId: 'product_specgroup',
  app: 'ecom',
  name: 'Product SpecGroup',
  keyVal: 'ProductSpecGroup',
  topicName: 'ProductSpecGroup',
  storeId: 'ProductSpecGroup',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'SpecGroup',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<ProductSpecGroupImageView item={item} />),
  queries: {
    read: QueryAction({
      query: ProductSpecGroupReadQuery,
      resourceNamePath: 'item.specgroup.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: ProductSpecGroupSearchQuery, responsePath: 'ecom_product_specgroup_search' }),
    search: QueryAction({
      query: ProductSpecGroupSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'specgroup.name',
    }),
    name: QueryAction({ query: ProductSpecGroupNameQuery, resourceNamePath: 'ecom_product_specgroup_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: ProductSpecGroupUpdateMutation, cs: ['specgroup'] }),
    create: MutationAction({ mutation: ProductSpecGroupCreateMutation }),
  },
});
