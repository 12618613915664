import { createGlobalStyle } from 'styled-components';
import { asRem, Breakpoint } from 'lib/css';
import CheckIcon from 'assets/icons/icon-check-black.svg';
import SearchIcon from 'assets/icons/icon-search.svg';
import CheckPlaceholderIcon from 'assets/icons/icon-check-placeholder.svg';
import DropDownIcon from 'assets/icons/icon-dropdown-white.svg';

export const GlobalStyle = createGlobalStyle`


html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  font-weight: 400;
  font-style: normal;
  color: var(--color-text-1);
  background-color: var(--color-primary-bg);
}

input, select, textarea, .input-proxy {
  width: 100%;
  background: var(--color-border-1);
  color: var(--color-text-1);
  font-size: var(--text-md-fontSize);
  line-height: var(--text-md-lineHeight);
  padding: ${asRem(12)} ${asRem(16)};
  border-radius: ${asRem(4)};
  outline: none;
  border: ${asRem(1)} solid transparent;
  display: block;
  margin-bottom: ${asRem(8)};
  font-family: var(--font-family);
  padding-right: ${asRem(25)};
  text-overflow: ellipsis;

  &:active, &:focus {
    outline: none;
    border: ${asRem(1)} solid var(--color-highlight);
  }
  &:placeholder {
    color: var(--color-disabled);
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: var( --color-text-1);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-border-1) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.disable-input-autofill {
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: var(--color-text-1);
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

::placeholder {
  color: var(--color-disabled-text);
  opacity: 1;
}

input[type=number] { 
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
  }
}
input[type=search] {
  background: url(${SearchIcon}) no-repeat;
  background-color: var(--color-border-1);
  background-position: top ${asRem(14)} left ${asRem(20)};
  padding: ${asRem(12)} ${asRem(20)} ${asRem(12)} ${asRem(48)};
  border-radius: ${asRem(8)};
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration { 
    display: none; 
  }
}
input[type=checkbox]{
  --checkbox-height: ${asRem(16)};
  display: grid;
  border-radius: ${asRem(4)};
  width: var(--checkbox-height);
  height: var(--checkbox-height);
  border: ${asRem(1)} solid var(--color-text-2);
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  background: transparent;
  position: relative;

  &:checked {
    border: none;
    &::after{
      content: '';
      background-color: var(--color-button-primary-bg);
      background-image: url(${CheckIcon});
      background-repeat: no-repeat;
      background-position: ${asRem(3)};
      color: var(--color-button-text-1);
      border-radius: ${asRem(4)};
      width: var(--checkbox-height);
      height: var(--checkbox-height);
      border: ${asRem(1)} solid var(--color-button-primary-bg);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &:active, &:focus {
    &::before {
      border: ${asRem(1)} solid var(--color-button-primary-bg);
    }
  }

  &.checkbox-large {
    --checkbox-height: ${asRem(42)};
    display: grid;
    border-radius: ${asRem(12)};
    border: ${asRem(1)} solid var(--color-border-1);
    background-color: var(--color-border-1);
    background-image: url(${CheckPlaceholderIcon});
    background-size: ${asRem(16)};
    background-repeat: no-repeat;
    background-position: ${asRem(12)};
    &:checked {
      border: none;
      &::after{
        content: '';
        background-color: var(--color-image-placeholder-bg);
        background-size: ${asRem(16)};
        background-position: ${asRem(12)};
        color: var(--color-button-text-1);
        border-radius: ${asRem(12)};
        border: ${asRem(1)} solid var(--color-image-placeholder-bg);
      }
    }

    &:active, &:focus {
      &::before {
        border: ${asRem(1)} solid var(--color-image-placeholder-bg);
      }
    }
  }
}

input[type=date] {
  padding: ${asRem(12)} ${asRem(16)};
  &::-webkit-calendar-picker-indicator {
    filter: invert(0.6);
  }
}

textarea { 
  width: 100%;
  appearance: none;
}

label {
  color: var(--color-label);
  display: block;
  font-weight: 400;
  font-size: ${asRem(14)};
  line-height: ${asRem(20)};
  margin-bottom: ${asRem(8)};
  text-transform: capitalize;
}

svg {
  display: inline-block;
  &.fill-icon {
    fill: var(--color-text-1);
    stroke: var(--color-text-rev);
  }
}

a, button {
  border-radius: ${asRem(5)};
  font-weight: bold;
  font-size: ${asRem(12)};
  line-height: ${asRem(18)};
  border: ${asRem(1)} solid transparent;
  padding: ${asRem(8)} ${asRem(10)};
  cursor: pointer;
  color: var(--color-button-text-1);
  background-color: var(--color-button-bg);
  text-decoration: none;
  transition: all 0.5s ease-out;
  @media screen and (min-width: ${Breakpoint.sm}) {
    padding: ${asRem(12)};
  }


  &:hover {
    text-decoration: none;
  }

  &.primary {
    background: var(--color-button-primary-bg);
    border-radius: ${asRem(4)};
    color: var(--color-button-text-2);
    font-size: var(--label-lg-fontSize);
    line-height: var(--label-lg-lineHeight);
    font-weight: var(--medium-fontWeight);
    border: none;
    cursor: pointer;
    text-align: center;
    @media screen and (min-width: ${Breakpoint.sm}) {
      font-size: var(--text-md-fontSize);
      line-height: var(--text-md-lineHeight);
    }
  }
  &.website-btn {
    background: var(--color-website-btn);
    border-radius: ${asRem(4)};
    color: var(--color-website-btn-text);
    border: ${asRem(1)} solid var(--color-website-btn);
    font-size: var(--text-md-fontSize);
    font-weight: normal;
    line-height: var(--text-md-lineHeight);
    padding: ${asRem(12)} ${asRem(36)};
    cursor: pointer;
    text-align: center;
    font-family: 'DM Sans', sans-serif;

    &:hover {
      background-color: transparent;
      color: var(--color-website-btn);
    }
  }
  &.secondary {
    background: var(--color-image-placeholder-bg);
    border-radius: ${asRem(4)};
    color: var(--color-image-placeholder-text);
    font-size: var(--label-lg-fontSize);
    line-height: var(--label-lg-lineHeight);
    font-weight: var(--medium-fontWeight);
    border: none;
    cursor: pointer;
    @media screen and (min-width: ${Breakpoint.sm}) {
      font-size: var(--text-md-fontSize);
      line-height: var(--text-md-lineHeight);
    }
  }
  &.alert {
    background: var(--color-alert);
    border-radius: ${asRem(4)};
    color: var(--color-text-1);
    font-size: var(--text-md-fontSize);
    line-height: var(--text-md-lineHeight);
    font-weight: var(--medium-fontWeight);
    border: none;
    cursor: pointer;
  }
  &.plain, &.style-3 {
    color: var(--color-secondary-cta);
    border-radius: ${asRem(4)};
    background: transparent;
    font-size: var(--label-lg-fontSize);
    line-height: var(--label-lg-lineHeight);
    font-weight: var(--medium-fontWeight);
    cursor: pointer;
    @media screen and (min-width: ${Breakpoint.sm}) {
      font-size: var(--text-md-fontSize);
      line-height: var(--text-md-lineHeight);
    }

    & svg {
      color: currentColor;
    }

    &:hover {
      text-decoration: none;
      color: var(--color-button-primary-bg);
    }

    &.hover-underline {
      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
  &.highlighted-btn {
    background: transparent;
    border: 1px solid var(--color-button-primary-bg);
    color: var(--color-button-primary-bg);
  }
  &.plain {
    font-weight: var(--regular-fontWeight);
  }
  &.style-3 {
    background-color: var(--color-button-3-bg);
  }
  &.small {
    font-size: var(--label-lg-fontSize);
    line-height: var(--label-lg-lineHeight);
    font-weight: var(--regular-fontWeight);
    padding: ${asRem(8)} ${asRem(10)};
    @media screen and (min-width: ${Breakpoint.sm}) {
      padding: ${asRem(8)} ${asRem(12)};
    }
  }

  &.clear {
    padding: 0;
    border: none;
    color: inherit;
    font: inherit;
  }

  &.bordered {
    border: ${asRem(1)} solid var(--color-border-2);
  }

  &.compact {
    padding: 0;
    margin-right: ${asRem(10)};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--color-disabled);
    color: var(--color-disabled-text);
  }
}

h1 {
  font-weight: bold;
  font-size: ${asRem(24)};
  line-height: ${asRem(31)};
}

td {
  &.number {
    text-align: right;
    font-family: var(--font-numbers);
  }
}

.rz-select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dropdown {
    height: ${asRem(20)};
  }
}

.rz-select {
  background: var(--color-table-border);
  color: var(--color-text-1);
  border-radius: ${asRem(2)};
  select {
    width: 100%;
    margin-bottom: 0;
    appearance: none;
    background-image: url(${DropDownIcon});
    background-size: ${asRem(8)};
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
  }
  >.dropdown {
    width: ${asRem(20)};
    position: absolute;
    right: ${asRem(10)};
    top: ${asRem(12)};
  }
}

.switch {
  position: relative;
  display: inline-block;
}
.switch-input {
  display: none;
}
.switch-label {
  display: block;
  width: 48px;
  height: 24px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  user-select: none;
}
.switch-label::before,
.switch-label::after {
  content: "";
  display: block;
  position: absolute;
  cursor: pointer;
}
.switch-label::before {
  width: 100%;
  height: 100%;
  background-color: #dedede;
  border-radius: 9999em;
  -webkit-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
}
.switch-label::after {
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  -webkit-transition: left 0.25s ease;
  transition: left 0.25s ease;
}
.switch-input:checked + .switch-label::before {
  background-color: #89c12d;
}
.switch-input:checked + .switch-label::after {
  left: 24px;
}

.active-layout-V2 {
  &.box {
    margin: var(--layout-t2-box-margin);
    border-radius: var(--layout-t2-box-radius);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

`;
