import React, { Suspense } from 'react';
import { Breadcrumb } from 'core/Breadcrumb';

import { WebsiteRoutes } from './website/routes';
import { WebTreeRoutes } from './webtree/routes';
import { PageRoutes } from './page/routes';
import { StructureRoutes } from './structure/routes';
import { ImageRoutes } from './image/routes';
import { AssetRoutes } from './asset/routes';
import { LinkListRoutes } from './linklist/routes';
import { StructureContentRoutes } from './structureContent/routes';

export function CmsRoutes() {
  return (
    <Suspense fallback={<>...</>}>
      <Breadcrumb />
      <WebsiteRoutes />
      <WebTreeRoutes />
      <PageRoutes />
      <StructureRoutes />
      <ImageRoutes />
      <AssetRoutes />
      <LinkListRoutes />
      <StructureContentRoutes />
    </Suspense>
  );
}
