import { ProductCategoryResource } from './category/resource';
import { ProductInventorySourceResource } from './inventory/resource';
import { GlobalPriceRuleResource } from './price/resource';
import { ProductResource } from './product/resource';
import { ProductKindResource } from './productKind/resource';
import { ProductSpecGroupResource } from './productSpecGroup/resource';
import { ProductSpecResource } from './productSpec/resource';

export const Resources = {
  ProductCategory: ProductCategoryResource,
  ProductInventorySource: ProductInventorySourceResource,
  GlobalPriceRule: GlobalPriceRuleResource,
  Product: ProductResource,
  ProductKind: ProductKindResource,
  ProductSpecGroup: ProductSpecGroupResource,
  ProductSpec: ProductSpecResource,
};

export function getEcomResourceDef(id) {
  return Resources[id] ?? null;
}
