export function getUserRole(user, accountUsers) {
  const role = accountUsers.find((accountUser) => (
    user.user.resource.key === accountUser?.access?.user?.key
  ));
  return role?.access?.role_v2;
}

export const UserRoles = {
  Owner: {
    key: 100,
    name: 'Owner',
  },
  Admin: {
    key: 101,
    name: 'Admin',
  },
  Staff: {
    key: 500,
    name: 'Staff',
  },
};

export function getUserRoleName(value, getKey) {
  const name = Object.values(UserRoles).filter((user) => user.key === value);
  if (getKey) {
    return name?.[0]?.key;
  }
  return name?.[0]?.name;
}

export const Roles = {
  SuperAdmin: {
    key: 1,
    name: 'Super Admin',
  },
  SuperStaff: {
    key: 50,
    name: 'Super Staff',
  },
  User: {
    key: 1000,
    name: 'User',
  },
  Guest: {
    key: 10000,
    name: 'Guest',
  },
};

export function getRoles(value) {
  const name = Object.values(Roles).filter((user) => user.key === value);
  return name?.[0]?.name;
}

export const SquadSteps = {
  Step1: 'Step1',
  Step2: 'Step2',
  Step3: 'Step3',
};

export const PlayingXIActions = {
  Add: 'Add',
  Removed: 'Removed',
  Replaced: 'Replaced',
};

export const PlayerRoles = {
  Batsman: 'Batsman',
  Keeper: 'Keeper',
  Bowler: 'Bowler',
  AllRounder: 'AllRounder',
};

export const AvailableSports = {
  Cricket: {
    key: 'Cricket',
    name: 'Cricket',
  },
  Football: {
    key: 'Football',
    name: 'Football',
  },
  Kabaddi: {
    key: 'Kabaddi',
    name: 'Kabaddi',
  },
  CircleJi: {
    key: 'CircleJi',
    name: 'Circle Ji',
  },
  FantasyEditor: {
    key: 'FantasyEditor',
    name: 'Fantasy Editor',
  },
  Ecom: {
    key: 'Ecom',
    name: 'E-commerce',
  },
  Cms: {
    key: 'Cms',
    name: 'CMS',
  },
  Other: {
    key: 'Other',
    name: 'Other',
  },
};

export const CricketFormats = {
  Oneday: {
    key: '50',
    name: 'Oneday',
    overs: '50 overs',
  },
  T20: {
    key: '20',
    name: 'T20',
    overs: '20 overs',
  },
  T10: {
    key: '10',
    name: 'T10',
    overs: '10 overs',
  },
  Test: {
    key: 'T',
    name: 'Test',
    overs: '4-5 days',
  },
  HundredBall: {
    key: 'H',
    name: 'HundredBall',
    overs: '100 balls',
  },
  SixtyBall: {
    key: 'S',
    name: 'SixtyBall',
    overs: '60 balls',
  },
  Other: {
    key: '-',
    name: 'Other',
  },
};

export const WizardSteps = {
  Step1: 'Step1',
  Step2: 'Step2',
  Step3: 'Step3',
  Step4: 'Step4',
  Step5: 'Step5',
  Step6: 'Step6',
};
