import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const ProductSpecCreateHintQuery = gql`
query ProductSpecCreateHintQuery($name: String!) {
  ecom_product_spec_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      spec {
        label
      }
    }
    key_suggestions
  }
}
`;

export const ProductSpecCreateMutation = gql`
mutation ProductSpecCreate(
  $spec: ProductSpecSpecInput!,
  $key: String,
) {
  ecom_product_spec_create(
    spec: $spec,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const ProductSpecReadQuery = gql`
query ProductSpecReadQuery($resource: ProductSpecInput!, $withHistory: Boolean) {
  ecom_product_spec_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      spec {
        label
        value_type
        searchable
        filterable
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const ProductSpecUpdateMutation = gql`
mutation ProductSpecUpdateMutation(
  $resource: ProductSpecInput!,
  $spec: ProductSpecSpecInput!,
  $version: RecordStage) {
  ecom_product_spec_update(
    resource: $resource,
    spec: $spec,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const ProductSpecDeleteMutation = gql`
mutation  ProductSpecDeleteMutation(
  $resource: ProductSpecInput!,
  $name: String!) {
  ecom_product_spec_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const ProductSpecSearchQuery = gql`
query ProductSpecSearchQuery($search: String!, $page: PagedRequestInput) {
  ecom_product_spec_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      spec {
        label
        value_type
        searchable
        filterable
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const ProductSpecNameQuery = gql`
query ProductSpecNameQuery($resource: ProductSpecInput!) {
  ecom_product_spec_name(resource: $resource) {
    name
  }
}
`;
