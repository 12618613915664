import { editorMetaCleanUpAndConvert, editorMetaPrepareItem } from 'core/editorMeta';

export const HeadElementUtils = {
  create() {
    return {
      kind: null,
      script: null,
      tag: null,
      link: null,
    };
  },

  buildTitle(item) {
    const kind = HeadElementUtils.identifyKind(item);
    const parts = [kind, '-'];

    if (kind === 'link') {
      if (item.link.id) {
        parts.push(`#${item.link.id}`);
      }
      if (item.link.href) {
        parts.push(item.link.href);
      }

      if (item.link.index) {
        parts.push(`(${item.link.index})`);
      }
    } else if (kind === 'script') {
      if (item.script.id) {
        parts.push(`#${item.script.id}`);
      }
      if (item.script.src) {
        parts.push(item.script.src);
      }
      if (item.script.index) {
        parts.push(`(${item.script.index})`);
      }
    } else if (kind === 'tag') {
      if (item.tag.id) {
        parts.push(`#${item.tag.id}`);
      }
      if (item.tag.tag) {
        parts.push(item.tag.tag);
      }
      if (item.tag.index) {
        parts.push(`(${item.tag.index})`);
      }
    }

    return parts.join(' ');
  },

  identifyKind(item) {
    const isLink = item.link && item.link.href;
    const isScript = item.script && (item.script.src || item.script.content);
    const isTag = item.tag && item.tag.tag;
    if (isLink) {
      return 'link';
    }
    if (isScript) {
      return 'script';
    }
    if (isTag) {
      return 'tag';
    }
    return null;
  },

  prepareForEdit(item) {
    const newItem = { ...item };
    const tags = ['link', 'script', 'tag'];
    tags.forEach((tag) => {
      const tagItem = newItem[tag];
      if (tagItem) {
        if (tagItem.attrs) {
          newItem[tag] = editorMetaPrepareItem({
            obj: tagItem,
            itemsProp: 'attrs',
          });
        } else {
          tagItem.attrs = {};
        }
      }
    });
    newItem.kind = HeadElementUtils.identifyKind(item);
    return newItem;
  },

  prepareForSave(item, cleanUp) {
    const newItem = { ...item };
    if (item.kind) {
      delete newItem.kind;
    }
    if (item.children) {
      delete newItem.children;
    }
    const tags = ['link', 'script', 'tag'];
    tags.forEach((tag) => {
      const tagItem = newItem[tag];
      if (tagItem) {
        if (tagItem.attrs) {
          newItem[tag] = editorMetaCleanUpAndConvert({
            obj: tagItem,
            itemsProp: 'attrs',
            cleanUp,
          });
        } else {
          tagItem.attrs = [];
        }
      }
    });
    return newItem;
  },
};
