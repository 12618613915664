import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const CmsAssetCreateMutation = gql`
mutation CmsAssetCreate($asset: CmsAssetAssetInput!, $key: String,
  $image: CmsAssetImageInput,
  $video: CmsAssetVideoInput,
  $audio: CmsAssetAudioInput) {
  cms_asset_create(
    key: $key,
    asset: $asset,
    image: $image,
    video: $video,
    audio: $audio,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    signed {
      signed_data
      url
      url_suffix
    }
  }
}
`;

export const CmsAssetReadQuery = gql`
query CmsAssetReadQuery($resource: CmsAssetInput!, $withHistory: Boolean) {
  cms_asset_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        type
        filename
        store_path
      }
      asset {
        filename
        store_path
        type
        alt
        desc
        cr_note
        version
        default_variation
      }
      image {
        format
        size
        variations {
          key
          media_query_name
          variation_name
          width
          height
          quality
          format
          resize
        }
      }
      video {
        format
        thumbnail_image
        resolution
        size
      }
      audio {
        format
        bitrate
      }
      computed {
        meta {
          version
          store_path
          width
          height
          size
          format
          variation_key
        }
        variations_meta
        total_size
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const CmsAssetUpdateMutation = gql`
mutation CmsAssetUpdateMutation(
  $resource: CmsAssetInput!,
  $asset: CmsAssetAssetInput!,
  $version: RecordStage) {
  cms_asset_update(
    resource: $resource,
    asset: $image,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const CmsAssetDeleteMutation = gql`
mutation  CmsAssetDeleteMutation(
  $resource: CmsAssetInput!,
  $name: String!) {
  cms_asset_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const CmsAssetSearchQuery = gql`
query CmsAssetSearchQuery($search: String!, $type: AssetType, $page: PagedRequestInput) {
  cms_asset_search(search: $search, type: $type, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        type
        filename
        store_path
      }
      asset {
        filename
        store_path
        type
        alt
        desc
        cr_note
        version
        default_variation
      }
      image {
        format
        size
        variations {
          key
          media_query_name
          variation_name
          width
          height
          quality
          format
          resize
        }
      }
      video {
        format
        thumbnail_image
        resolution
        size
      }
      audio {
        format
        bitrate
      }
      computed {
        meta {
          version
          store_path
          width
          height
          size
          format
          variation_key
        }
        variations_meta
        total_size
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const CmsAssetNameQuery = gql`
query CmsAssetNameQuery($resource: CmsAssetInput!) {
  cms_asset_name(resource: $resource) {
    name
  }
}
`;
