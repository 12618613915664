import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserContext } from 'core/UserContext';
import PropTypes from 'prop-types';
import { asRem } from 'lib/css';
import { dateFromTsTimezone, changeTimeZone, relativeFriendlyDateTime } from 'lib/utils';
import { Label12Regular, Text14Regular } from 'style/typography';
import { getPreferredTimeZone } from 'core/localStore';
import { ReactComponent as UTCIcon } from 'assets/icons/icon-time.svg';
import { ReactComponent as UserIcon } from 'assets/icons/icon-user-time.svg';
import { ReactComponent as StadiumIcon } from 'assets/icons/icon-stadium.svg';
import { Tooltip } from './Tooltip';

const DateTimeToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${asRem(6)};
  justify-content: end;
  button {
    padding: 0;
    line-height: inherit;
    color: var(--color-text-1);
  }
`;

export function TimeZoneIcon({ timezone }) {
  switch (timezone) {
    case 'Stadium':
      return (
        <StadiumIcon />
      );
    case 'Local':
      return (
        <UserIcon />
      );
    default:
      return (
        <UTCIcon />
      );
  }
}

TimeZoneIcon.propTypes = {
  timezone: PropTypes.string,
};

export function DateTimeToggle({
  stadiumTime, date, format, disableIcon,
  renderItem,
}) {
  const userContext = useContext(UserContext);
  return (
    <DateTimeToggleWrapper>
      <button
        type="button"
        onClick={
          () => changeTimeZone(
            getPreferredTimeZone(),
            userContext,
          )
        }
      >
        {!disableIcon && (
          <TimeZoneIcon timezone={getPreferredTimeZone()} />
        )}
      </button>
      {renderItem ? renderItem()
        : (
          <Text14Regular>
            {dateFromTsTimezone(
              date,
              getPreferredTimeZone(),
              true,
              stadiumTime,
              format,
            )}
          </Text14Regular>
        )}

    </DateTimeToggleWrapper>
  );
}
DateTimeToggle.propTypes = {
  date: PropTypes.any,
  stadiumTime: PropTypes.string,
  format: PropTypes.string,
  disableIcon: PropTypes.bool,
  renderItem: PropTypes.func,
};

export function FriendlyDateTime({ date }) {
  const parsed = relativeFriendlyDateTime({ date });
  return (
    <Tooltip
      text={parsed.formated}
      renderTrigger={() => (
        <Label12Regular>
          {parsed.relative || parsed.formated}
        </Label12Regular>
      )}
    />
  );
}

FriendlyDateTime.propTypes = {
  date: PropTypes.any,
};
