import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
  // RefField,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import { CmsAssetView } from './ResourceImage';
import {
  CmsAssetSearchQuery,
  CmsAssetReadQuery,
  CmsAssetCreateMutation,
  CmsAssetUpdateMutation,
  CmsAssetNameQuery,
} from './query';

const StoreTypeMap = {
  Checkbox: '_bool',

  SmallNumber: '_int',
  LargeNumber: '_int',

  Decimal: '_float',
  Price: '_float',

  Date: '_datetime',
  DateTime: '_datetime',
  Time: '_datetime',
};

export const CmsAssetResource = Resource({
  resourceId: 'asset',
  app: 'cms',
  name: 'Asset',
  keyVal: 'CmsAsset',
  topicName: 'CmsAsset',
  storeId: 'CmsAsset',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Asset',
      shape: Yup.object().shape({
        filename: StringFieldMinMax(3, 120),
        type: Yup.string().required(),
        // groups: Yup.array().of(Yup.object().shape({
        //   fields: Yup.array().of(Yup.object().shape({
        //     key: StringFieldMinMax(1, 120),
        //   })).nullable().default([]),
        // })).nullable().default([]),
      }),

    }),
  ],
  listPrimaryActions: [],
  imageRender: (item) => (<CmsAssetView item={item} />),
  queries: {
    read: QueryAction({
      query: CmsAssetReadQuery,
      resourceNamePath: 'item.asset.filename',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: CmsAssetSearchQuery, responsePath: 'cms_image_search' }),
    search: QueryAction({
      query: CmsAssetSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.filename',
    }),
    name: QueryAction({ query: CmsAssetNameQuery, resourceNamePath: 'cms_image_name.name' }),
  },
  mutations: {
    update: MutationAction({
      mutation: CmsAssetUpdateMutation,
      cs: ['asset'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        if (!resp.image.groups) {
          resp.image.groups = [{ fields: [] }];
        }
        resp.image.groups = resp.image.groups.map((x) => {
          return {
            ...x,
            fields: arrayAsObject(x.fields),
          };
        });
        resp.image.groups = arrayAsObject(
          resp.image.groups,
        );
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.image.groups = objectAsArray(resp.image.groups);
        Object.keys(resp.image.groups).forEach((key) => {
          resp.image.groups[key].fields = objectAsArray(resp.image.groups[key].fields);
          Object.keys(resp.image.groups[key].fields).forEach((fkey) => {
            const afield = resp.image.groups[key].fields[fkey];
            let resolvedType = '_str';
            if (StoreTypeMap[afield.editor_type]) {
              resolvedType = StoreTypeMap[afield.editor_type];
            }
            afield.store_type = resolvedType;
          });
        });
        return resp;
      },
    }),
    create: MutationAction({ mutation: CmsAssetCreateMutation }),
  },
});
