import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  Formik, Form, useFormikContext,
} from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { Box } from 'core/Box';
import { dataFromPath } from 'lib/utils';
import { ResponseStatus, StatusError, StatusLoading } from './Status';

function FormChanges({ onChange }) {
  const {
    values,
    isValid,
    dirty,
    isSubmitting,
  } = useFormikContext();
  useEffect(() => {
    onChange({
      values,
      isValid,
      dirty,
      isSubmitting,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isValid, dirty, isSubmitting]);

  return null;
}

FormChanges.propTypes = {
  onChange: PropTypes.func,
};

const CreateEditControllerWrapper = styled.div`
`;

export function CreateEditController({
  itemKey,
  buttonText,
  initValues,
  title = null,
  schema,
  mutation,
  responseKey,
  responseStatusKey = null,
  prepareVariables,
  onChange,
  onDirty,
  onFormCanSave,
  watchChanges = true,
  children,
  renderMoreContent,
  footerContent,
  onSuccess,
}) {
  const formInitValues = {
    ...initValues,
  };

  const [
    doSave,
    { loading, error, data },
  ] = useMutation(mutation);

  const [formChanged, setFormChanged] = useState(false);
  const [formCanSave, setFormCanSave] = useState(false);
  const [capturedValues, setCapturedValues] = useState(null);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    doSave({
      variables: {
        key: itemKey || null,
        ...prepareVariables(values),
      },
    }).then((resp) => {
      const respData = resp && resp.data && dataFromPath(resp.data, responseKey);
      const statusData = respData && dataFromPath(resp.data, responseStatusKey || responseKey);
      setSubmitting(false);
      if (respData && statusData.okay && onSuccess) {
        onSuccess(respData);
      }
    }).catch(() => {
      setSubmitting(false);
    });
    return true;
  };

  const handleFormChange = ({
    values,
    isValid,
    dirty,
    isSubmitting,
  }) => {
    if (!isValid) {
      setFormCanSave(false);
      return;
    }

    if (isSubmitting) {
      setFormCanSave(false);
      return;
    }

    const hasWatchFunction = onChange || onDirty || onFormCanSave;

    if ((!hasWatchFunction) && (!watchChanges)) { return; }

    const preparedVariables = prepareVariables(values);
    const raw = JSON.stringify(preparedVariables);

    if (formChanged !== dirty) {
      setFormChanged(dirty);
    }
    if (formCanSave !== dirty) {
      setFormCanSave(dirty);
    }

    if (capturedValues !== raw) {
      setCapturedValues(raw);
      if (onChange) {
        onChange({ values, preparedVariables });
      }
    }
  };

  useEffect(() => {
    if (onDirty) {
      onDirty(formChanged);
    }
  }, [formChanged, onDirty]);

  useEffect(() => {
    if (onFormCanSave) {
      onFormCanSave(formCanSave);
    }
  }, [onFormCanSave, formCanSave]);

  const response = dataFromPath(data, responseStatusKey || responseKey);

  return (
    <CreateEditControllerWrapper>
      <Formik
        initialValues={formInitValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          values,
          setFieldValue,
        }) => (
          <Form>
            <FormChanges onChange={handleFormChange} />
            {(title || children) && (
              <Box center>
                {title && (
                  <Box.Title>
                    {title}
                  </Box.Title>
                )}
                <Box.Content>
                  {children}
                </Box.Content>
              </Box>
            )}
            {renderMoreContent && renderMoreContent({ values, setFieldValue })}
            <Box center>
              <Box.Content className="dailog-button-wrapper">
                <div className="form-status">
                  {loading && (<StatusLoading message="Hold on..." />)}
                  {error && (<StatusError error={error} />)}
                  {response && (<ResponseStatus status={response} />)}
                </div>
                {buttonText
                  ? (
                    <button className="primary" type="submit" disabled={!formCanSave}>
                      {buttonText}
                    </button>
                  )
                  : (
                    <button className="primary" type="submit" disabled={!formCanSave}>
                      {itemKey ? 'Save' : 'Create'}
                    </button>
                  )}
              </Box.Content>
            </Box>
            {footerContent && footerContent({ values, setFieldValue })}
          </Form>
        )}
      </Formik>
    </CreateEditControllerWrapper>
  );
}

CreateEditController.propTypes = {
  itemKey: PropTypes.string,
  initValues: PropTypes.object,
  title: PropTypes.string,
  schema: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
  prepareVariables: PropTypes.func,
  onChange: PropTypes.func,
  onDirty: PropTypes.func,
  onFormCanSave: PropTypes.func,
  watchChanges: PropTypes.bool,
  responseKey: PropTypes.string.isRequired,
  responseStatusKey: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  renderMoreContent: PropTypes.func,
  footerContent: PropTypes.func,
  onSuccess: PropTypes.func,
  buttonText: PropTypes.string,
};

const GetControllerWrapper = styled.div`
`;

export function GetController({
  resourceDef,
  query,
  responseKey,
  variables,
  render,
  resourceKey,
  resource,
}) {
  const params = useParams();
  const resolvedResource = resource || {
    ...resourceDef.resourceFromKey(resourceDef, resourceKey ?? params.key),
  };
  const {
    loading, data, error, refetch,
  } = useQuery(query, {
    variables: { resource: resolvedResource, ...(variables || {}) },
  });
  if (!data && loading) return (<StatusLoading />);
  if (!data && error) return (<StatusError error={error} />);
  const item = dataFromPath(data, responseKey);
  if (!item) return (<StatusError error={{ message: 'Item missing. Something went wrong' }} />);

  return (
    <GetControllerWrapper>
      {render(item, refetch)}
    </GetControllerWrapper>
  );
}

GetController.propTypes = {
  resourceDef: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  responseKey: PropTypes.string.isRequired,
  variables: PropTypes.object,
  render: PropTypes.func,
  resourceKey: PropTypes.string,
  resource: PropTypes.object,
};
