import React from 'react';
import PropTypes, { bool } from 'prop-types';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { asRem } from 'lib/css';
import { Text14Regular } from 'style/typography';
import { isActiveParent } from './pages';

const MenuBarWrapper = styled.div`
background: var(--color-secondary-bg);
padding: ${asRem(14)} ${asRem(28)};

display: flex;
flex-wrap: wrap;
gap: ${asRem(20)};
align-items: center;

>a {
  --item-color: var(--color-text-2);

  display: block;
  color: var(--item-color);
  background: none;

  padding: 0;
  border-width: 0;
  transition: all 0.3s ease-out;

  display: flex;
  gap: ${asRem(8)};
  align-items: center;

  svg, span {
    display: block;
  }

  svg {
    path {
      fill: var(--item-color);
    }
  }

  &:hover, &.active {
    --item-color: var(--color-button-primary-bg);
  }
}
`;

export function MenuBar({ links, isSecondaryMenu }) {
  const location = useLocation();

  return (
    <MenuBarWrapper className={`menu-bar-container ${isSecondaryMenu ? 'remove-padding' : ''}`}>
      {links.filter((link) => link !== null).map((link) => (
        <NavLink
          exact="true"
          className={isActiveParent(location.pathname, link.link) ? 'active' : ''}
          to={link.url ? link.url : (link.link.meta.url || '/')}
          key={link.key || link.name}
        >
          {link.icon}
          <Text14Regular as="span">{link.name}</Text14Regular>
        </NavLink>
      ))}
    </MenuBarWrapper>
  );
}

MenuBar.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.object,
    icon: PropTypes.node,
  })),
  isSecondaryMenu: bool,
};
