import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
  // RefField,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import { CmsImageImageView } from './ResourceImage';
import {
  CmsImageSearchQuery,
  CmsImageReadQuery,
  CmsImageCreateMutation,
  CmsImageUpdateMutation,
  CmsImageNameQuery,
} from './query';

const StoreTypeMap = {
  Checkbox: '_bool',

  SmallNumber: '_int',
  LargeNumber: '_int',

  Decimal: '_float',
  Price: '_float',

  Date: '_datetime',
  DateTime: '_datetime',
  Time: '_datetime',
};

export const CmsImageResource = Resource({
  resourceId: 'image',
  app: 'cms',
  name: 'Image',
  keyVal: 'CmsImage',
  topicName: 'CmsImage',
  storeId: 'CmsImage',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Image',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        // groups: Yup.array().of(Yup.object().shape({
        //   fields: Yup.array().of(Yup.object().shape({
        //     key: StringFieldMinMax(1, 120),
        //   })).nullable().default([]),
        // })).nullable().default([]),
      }),

    }),
    // ColumnSet({
    //   name: 'Desc',
    //   shape: Yup.object().shape({
    //     desc: Yup.string().max(1024, 'Too big').nullable().default(''),
    //     image: Yup.string(),
    //   }),
    // }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<CmsImageImageView item={item} />),
  queries: {
    read: QueryAction({
      query: CmsImageReadQuery,
      resourceNamePath: 'item.image.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: CmsImageSearchQuery, responsePath: 'cms_image_search' }),
    search: QueryAction({
      query: CmsImageSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.name',
    }),
    name: QueryAction({ query: CmsImageNameQuery, resourceNamePath: 'cms_image_name.name' }),
  },
  mutations: {
    update: MutationAction({
      mutation: CmsImageUpdateMutation,
      cs: ['image'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        if (!resp.image.groups) {
          resp.image.groups = [{ fields: [] }];
        }
        resp.image.groups = resp.image.groups.map((x) => {
          return {
            ...x,
            fields: arrayAsObject(x.fields),
          };
        });
        resp.image.groups = arrayAsObject(
          resp.image.groups,
        );
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.image.groups = objectAsArray(resp.image.groups);
        Object.keys(resp.image.groups).forEach((key) => {
          resp.image.groups[key].fields = objectAsArray(resp.image.groups[key].fields);
          Object.keys(resp.image.groups[key].fields).forEach((fkey) => {
            const afield = resp.image.groups[key].fields[fkey];
            let resolvedType = '_str';
            if (StoreTypeMap[afield.editor_type]) {
              resolvedType = StoreTypeMap[afield.editor_type];
            }
            afield.store_type = resolvedType;
          });
        });
        // resp.image = {
        //   active: resp.image.active,
        //   name: resp.image.name,
        //   groups: resp.image.groups,
        // };
        return resp;
      },
    }),
    create: MutationAction({ mutation: CmsImageCreateMutation }),
  },
});
