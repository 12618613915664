/* eslint-disable no-debugger */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { ListView } from 'core/List';
import { dataFromPath } from 'lib/utils';
import { Label12Regular, Text14Regular } from 'style/typography';
import { ReactComponent as NextIcon } from 'assets/icons/icon-pagination-next.svg';
import { ReactComponent as PrevIcon } from 'assets/icons/icon-pagination-prev.svg';
import { Filter } from './Filters/Filter';

const ListControllerWrapper = styled.div`
  display: flex;
  align-items: start;
  padding: 0 ${asRem(28)};
  gap: ${asRem(28)};

  table {
    border-collapse: separate;
    tr {
      td {
        font-size: var(--text-md-fontSize);
        line-height: var(--text-md-lineHeight);
        font-weight: var(--regular-fontWeight);
        vertical-align: middle;

        .with-image {
          display: flex;
          gap: ${asRem(10)};
          align-items: center;
        }

        .plain {
          padding: 0;
          margin-right: ${asRem(12)};
        }
      }
    }
  }

  .pagination {
    margin-left: ${asRem(10)};
    display: flex;
    align-items: center;
    gap: ${asRem(10)};
    .currect-page {
      color: var(--color-label);
    }
  }
  .total-items {
    display: flex;
    align-items: center;
    gap: ${asRem(10)};
    font-size: ${asRem(12)}
  }

  .action-header {
    padding: ${asRem(6)} ${asRem(28)};
    background: var(--color-secondary-bg);
    display: flex;
    justify-content: space-between;
    .header-content {
      input {
        display: inline-block;
        vertical-align: top;
        margin-right: ${asRem(12)};
        margin-top: ${asRem(12)};
      }
      .text-14-regular {
        display: inline-block;
        vertical-align: top;
        margin-top: ${asRem(10)};
      }
    }
  }

  .filter-container {
    padding: ${asRem(20)};
    border: 1px solid var(--color-border-1);
    border-radius: ${asRem(20)};
    background: var(--color-secondary-bg);
    flex: 0 0 ${asRem(280)};
  }

  .actions {
    div{
      display: flex;
      flex-direction: column;

      span{
        margin-top: ${asRem(10)};
      }
    }
  }
`;

export function ListController({
  query,
  responseKey,
  itemsKey,
  title,
  renderRows,
  renderHeader,
  renderSelectedItemActions,
  reload = 0,
  actions = null,
  variables,
  actionPath = null,
  actionName = null,
  renderRowHead = null,
  renderRowFoot = null,
  useTable = true,
  useSearch = true,
  useInlineTools = false,
  emptyMessage = null,
  renderEmpty = null,
  tagFilter,
  renderTitle,
  resourceID,
  enableFilters,
  internalFilters,
  selectedTableContent,
  addedTableContent,
  resourceDef,
  showdisabled = false,
  warningmsg,
  showList = true,
  showTotal = false,
  renderAdditionalFilters = null,
  onRemoveFilters = null,
  renderAdditionalFilterTags = null,
  showEmptyMsg = true,
}) {
  const navigate = useNavigate();
  const searchQuery = useLocation().search;
  const searchKey = new URLSearchParams(searchQuery).get('search');
  const type = new URLSearchParams(searchQuery).get('type');
  const pageNo = new URLSearchParams(searchQuery).get('page');

  const [lastReload, setLastReload] = useState(0);
  const [pageKey, setPageKey] = useState(pageNo && useTable ? pageNo : '1');
  const [select, setSelect] = useState(0);
  const [checkAll, setCheckAll] = useState(false);
  const [addedFilters, setAddedFilters] = useState([]);
  const [filterPostVariables, setFilterPostVariables] = useState();
  const [additionalFilterVariables, setAdditionalFilterVariables] = useState();
  const [changeChosenFilters, setChangeChosenFilters] = useState(false);

  const [searchValue, setSearchValue] = useState(searchKey || null);

  let postVariables;

  if (!tagFilter && !enableFilters) {
    postVariables = useTable ? {
      ...(variables || {}),
      page: {
        page_key: pageKey,
      },
      search: searchValue || variables.search,
    } : { pageKey, ...(variables || {}) };
  } else {
    postVariables = useTable ? {
      ...(variables || {}),
      page: {
        page_key: pageKey,
      },
      search: searchValue || variables.search,
      tags: addedFilters,
      filters: filterPostVariables,
      ...(additionalFilterVariables && additionalFilterVariables),
      show_hidden: variables?.show_hidden,
    } : { pageKey, ...(variables || {}) };
  }

  const {
    loading,
    data,
    error,
    refetch,
  } = useQuery(query, {
    variables: postVariables,
  });
  let resp;
  let items;

  if (data && responseKey) {
    resp = dataFromPath(data, responseKey);
    items = dataFromPath(resp, itemsKey) || [];
  }
  if (reload !== lastReload) {
    console.log('Reloading', reload, lastReload);
    refetch();
    setLastReload(reload);
  }

  let selectEveryRow = false;
  let showSelectedItemsHeader = true;

  const selectAllRows = (event) => {
    const { checked } = event.target;
    setCheckAll(checked);
  };

  selectEveryRow = checkAll;

  const selectRow = (event) => {
    const { checked } = event.target;
    if (checked) {
      setSelect(select + 1);
    } else {
      setSelect(select - 1);
    }
  };

  const addQueryParams = (key, value) => {
    let queryParams = {};
    if (type && type !== null) {
      if (searchValue !== '' && searchValue !== null) {
        queryParams = { type, page: key, search: (value ?? searchValue) };
      } else {
        queryParams = { type, page: key };
      }
    } else if (searchValue !== '' && searchValue !== null) {
      queryParams = { page: key, search: (value ?? searchValue) };
    } else {
      queryParams = { page: key };
    }
    navigate(
      {
        search: `?${new URLSearchParams(queryParams).toString()}`,
      },
    );
  };

  useEffect(() => {
    if (pageKey) {
      refetch();
      setLastReload(reload);
    }
  }, [pageKey, refetch, reload]);

  if (select > 0) {
    showSelectedItemsHeader = true;
  } else {
    showSelectedItemsHeader = false;
  }
  let pageTools;
  if (resp && resp.page && (resp.page.prev_page_key || resp.page.next_page_key)) {
    pageTools = (
      <div className="pagination">
        <Text14Regular className="current-page">
          Page
          {' '}
          {resp.page.next_page_key > 0
          && resp.page.next_page_key !== null
            ? parseInt(resp.page.next_page_key, 10) - 1 : parseInt(resp.page.prev_page_key, 10) + 1}
        </Text14Regular>
        {resp.page.prev_page_key && (
          <button
            type="button"
            className="plain"
            onClick={() => {
              setPageKey(resp.page.prev_page_key);
              addQueryParams(resp.page.prev_page_key);
            }}
          >
            <PrevIcon />
          </button>
        )}
        {resp.page.next_page_key && (
          <button
            type="button"
            className="plain"
            onClick={() => {
              setPageKey(resp.page.next_page_key);
              addQueryParams(resp.page.next_page_key);
            }}
          >
            <NextIcon />
          </button>
        )}
      </div>
    );
  }

  const onTagChange = (tag) => {
    let arr = [...addedFilters];
    if (!addedFilters?.includes(tag)) {
      arr.push(tag);
      setAddedFilters(arr);
    } else {
      arr = arr.filter((val) => val !== tag);
      setAddedFilters(arr);
    }
  };

  const onChangeFilters = (filter, isAdditional) => {
    const removeTag = filter[0];
    const { [removeTag]: removeFilter, ...restValues } = isAdditional
      ? additionalFilterVariables : filterPostVariables;
    if (isAdditional) {
      setAdditionalFilterVariables(restValues);
    } else {
      setFilterPostVariables(restValues || {});
    }
    setChangeChosenFilters(true);
    if (onRemoveFilters) {
      onRemoveFilters(filter);
    }
  };

  const getFilterPostVariables = (val) => {
    setFilterPostVariables(val);
  };

  const getChosenFilters = (changeFilters) => {
    changeFilters(filterPostVariables);
    setChangeChosenFilters(false);
  };

  useEffect(() => {
    refetch();
  }, [additionalFilterVariables, filterPostVariables]);

  return (
    <ListControllerWrapper>
      {(tagFilter || enableFilters) && (
        <div className="filter-container">
          <Filter
            resourceID={resourceID}
            onTagChange={onTagChange}
            tagFilter={tagFilter}
            addedFilters={addedFilters}
            addedTableContent={addedTableContent}
            internalFilters={internalFilters}
            selectedTableContent={selectedTableContent}
            enableFilters={enableFilters}
            resourceDef={resourceDef}
            getFilterPostVariables={getFilterPostVariables}
            renderAdditionalFilters={renderAdditionalFilters}
            setAdditionalFilterVariables={setAdditionalFilterVariables}
            additionalFilterVariables={additionalFilterVariables}
            changeChosenFilters={changeChosenFilters}
            getChosenFilters={getChosenFilters}
          />
        </div>
      )}
      <ListView
        useTable={useTable}
        title={title}
        renderTitle={renderTitle}
        items={items}
        data={data}
        addedFilters={addedFilters}
        onTagChange={onTagChange}
        onChangeFilters={onChangeFilters}
        loading={loading}
        filterPostVariables={filterPostVariables}
        renderRowFoot={renderRowFoot}
        emptyMessage={emptyMessage}
        renderEmpty={renderEmpty}
        error={error}
        showList={showList}
        showEmptyMsg={showEmptyMsg}
        actions={(actions || actionPath) && (
          <div>
            {showdisabled && <button type="button" disabled className="primary">{actionName}</button>}
            {actions && actions(resp)}
            {!showdisabled && actionPath && (
              <Link to={actionPath} className="primary">
                {actionName || 'New'}
              </Link>
            )}
            {warningmsg && <Label12Regular>{warningmsg}</Label12Regular>}
          </div>
        )}
        search={useSearch ? (
          <input
            type="search"
            placeholder={`Search ${title}`}
            value={searchValue}
            onChange={(event) => {
              addQueryParams('1', event.target.value);
              setSearchValue(event.target.value);
              setPageKey('1');
            }}
          />
        ) : ''}
        additionalFilterVariables={additionalFilterVariables}
        renderAdditionalFilters={renderAdditionalFilters}
        renderAdditionalFilterTags={renderAdditionalFilterTags}
      >
        {data && (
          <div>
            {
            showSelectedItemsHeader && (
              <div className="action-header">
                <div className="header-content">
                  <input type="checkbox" checked />
                  <Text14Regular>
                    {select}
                    {' '}
                    {(select > 1) ? 'Items' : 'Item'}
                    {' '}
                    selected
                  </Text14Regular>
                </div>
                {renderSelectedItemActions && renderSelectedItemActions(select)}
              </div>
            )
          }
            {renderHeader && renderHeader(items)}
            {useTable && (
              <table>
                <thead>
                  {showSelectedItemsHeader
                    ? ''
                    : renderRowHead(resp, selectAllRows)}
                </thead>
                <tbody>
                  {selectEveryRow
                    ? renderRows(items, selectRow, selectEveryRow, resp)
                    : renderRows(items, selectRow, resp)}
                  {renderRowFoot && renderRowFoot(resp)}
                </tbody>
              </table>
            )}
            {!useTable && (
              <div className="items">
                {renderRowHead && renderRowHead(resp)}
                {renderRows(items, resp)}
                {useInlineTools && pageTools}
                {renderRowFoot && renderRowFoot(resp)}
              </div>
            )}
            <div className="page-tools">
              {showTotal && resp.page.count && (
                <div className="total-items">
                  {`(${resp.page.count} items)`}
                </div>
              )}
              {(!useInlineTools) && pageTools}
            </div>
          </div>
        )}
      </ListView>
    </ListControllerWrapper>
  );
}

ListController.propTypes = {
  query: PropTypes.object.isRequired,
  responseKey: PropTypes.string.isRequired,
  itemsKey: PropTypes.string.isRequired,
  variables: PropTypes.object,
  title: PropTypes.string.isRequired,
  renderTitle: PropTypes.func,
  actionPath: PropTypes.string,
  actionName: PropTypes.string,
  renderRowHead: PropTypes.func,
  renderRowFoot: PropTypes.func,
  renderRows: PropTypes.func,
  renderSelectedItemActions: PropTypes.func,
  renderHeader: PropTypes.func,
  actions: PropTypes.func,
  reload: PropTypes.number,
  useTable: PropTypes.bool,
  useSearch: PropTypes.bool,
  useInlineTools: PropTypes.bool,
  renderEmpty: PropTypes.func,
  emptyMessage: PropTypes.string,
  tagFilter: PropTypes.bool,
  resourceID: PropTypes.string,
  enableFilters: PropTypes.bool,
  internalFilters: PropTypes.array,
  selectedTableContent: PropTypes.any,
  addedTableContent: PropTypes.array,
  resourceDef: PropTypes.object,
  showdisabled: PropTypes.bool,
  warningmsg: PropTypes.string,
  showList: PropTypes.bool,
  showTotal: PropTypes.bool,
  renderAdditionalFilters: PropTypes.func,
  onRemoveFilters: PropTypes.func,
  renderAdditionalFilterTags: PropTypes.func,
  showEmptyMsg: PropTypes.bool,
};
