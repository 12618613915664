import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const ProductInventorySourceCreateHintQuery = gql`
query ProductInventorySourceCreateHintQuery($name: String!) {
  ecom_inventory_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      source {
        name
        internal_name
      }
    }
    key_suggestions
  }
}
`;

export const ProductInventorySourceCreateMutation = gql`
mutation ProductInventorySourceCreate(
  $source: ProductInventorySourceSourceInput!,
  $key: String,
) {
  ecom_inventory_create(
    source: $source,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const ProductInventorySourceReadQuery = gql`
query ProductInventorySourceReadQuery($resource: ProductInventorySourceInput!, $withHistory: Boolean) {
  ecom_inventory_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      source {
        name
        internal_name
      }
      moveable {
        moveable {
          location
          cutoff_time
          cutoff_weekday
          moveable_days
          hours_to_move
          max_weight
          max_volume
        }
      }
      location {
        is_physical
        zip_code
        address
        geo_location
      }

    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const ProductInventorySourceUpdateMutation = gql`
mutation ProductInventorySourceUpdateMutation(
  $resource: ProductInventorySourceInput!,
  $source: ProductInventorySourceSourceInput!,
  $version: RecordStage) {
  ecom_inventory_update(
    resource: $resource,
    source: $source,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const ProductInventorySourceDeleteMutation = gql`
mutation  ProductInventorySourceDeleteMutation(
  $resource: ProductInventorySourceInput!,
  $name: String!) {
  ecom_inventory_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const ProductInventorySourceSearchQuery = gql`
query ProductInventorySourceSearchQuery($search: String!, $page: PagedRequestInput) {
  ecom_inventory_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      source {
        name
        internal_name
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const ProductInventorySourceNameQuery = gql`
query ProductInventorySourceNameQuery($resource: ProductInventorySourceInput!) {
  ecom_inventory_name(resource: $resource) {
    name
    internal_name
  }
}
`;
