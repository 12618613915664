import React, {
  lazy,
  useContext,
  Suspense,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { asRem, Breakpoint } from 'lib/css';
import { Text14Bold, Label10Medium } from 'style/typography';
// import { ReactComponent as IconNotification } from 'assets/icons/icon-notification.svg';
import { ReactComponent as IconLogo } from 'assets/icons/icon-rz-logo.svg';
import { ReactComponent as PullDownMenuIcon } from 'assets/icons/icon-dropdown.svg';
import { ReactComponent as BurgerMenu } from 'assets/icons/icon-menu.svg';
import { SportsDropdown } from 'core/SportsDropdown';
import { UserDropdown } from 'core/UserDropdown';
import { AvailableSports } from 'modal';
import { FantasyMenu } from 'Fantasy/Menu';
import { useLazyQuery } from '@apollo/client';
import { SportsAccountGet } from 'account/query';
import { Config } from 'config';
import { ScreenPopover } from './ScreenPopover';
import { getToken, getAccountKey, saveRoanuzMode } from './localStore';
import { UserContext } from './UserContext';
import { AppContext } from './AppContext';
import { MenuBar } from './MenuBar';

const CricketMenu = lazy(() => import('sports/cricket/Menu'));
const FootballMenu = lazy(() => import('sports/football/Menu'));
const EcomMenu = lazy(() => import('ecom/Menu'));
const CmsMenu = lazy(() => import('cms/Menu'));

const TopBarWrapper = styled.div`
  background: var(--color-secondary-bg);
  height: inherit;
  border-bottom: 1px solid var(--color-table-border);
  .first-bar {
    display: flex;
    justify-content: space-between;
    height: var(--layout-top-height);
    align-items: center;
    padding: 0 ${asRem(10)}; 
    @media screen and (min-width: ${Breakpoint.sm}) {
      padding: 0 ${asRem(28)};
    }
    >div {
      display: flex;
      align-items: center;
      gap: ${asRem(10)};
    }
    .logo-header {
      display: flex;
      align-items: center;
      background: transparent;
      padding: 0;
      margin: ${asRem(10)} 0;
      .logo {
        width: ${asRem(38)};
        height: ${asRem(38)};
      }
      >div {
        display: flex;
        align-items: center;
        justify-content: center;
        &>span {
          color: var(--color-text-1);
          display: inline-block;
          vertical-align: top;
          margin-left: ${asRem(10)};
        }
        .label {
          padding: ${asRem(2)} ${asRem(4)};
          border-radius: ${asRem(4)};
          background: #FFA134;
          color: #333;

          &.live {
            background: var(--color-active-status);
            color: var(--color-text-1);
          }
        }
      }
    }
    aside {
      display: flex;
      margin: ${asRem(10)} 0;
      align-items: center;
      .bell-icon {
        display: inline-block;
        vertical-align: top;
        margin-top: ${asRem(10)};
      }
    }
  }
  .second-bar {
    display: none;
    @media screen and (min-width: ${Breakpoint.sm}) {
      display: flex;
    }
  }

  .burger-menu-container {
    display: flex;
    @media screen and (min-width: ${Breakpoint.sm}) {
      display: none;
    }
  }
  
  .import-container {
    display: none;
    gap: ${asRem(6)};
    padding: 0;
    color: var(--color-secondary-cta);

    &:hover {
      color: var(--color-button-primary-bg);
    }
  }

  .mobile-view-menu {
    flex-direction: column;
  }
`;

const MobileMenuWrapper = styled.div`
  .menu-bar-container {
    flex-direction: column;
    align-items: start;
  }
`;

export function TopBar() {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const [showPopup, setShowPopup] = useState(false);
  const token = getToken();
  let secondMenu = null;

  const className = `active-layout-${appContext.viewState.layoutWidth} box`;

  const [getConfig, {
    data: ConfigData,
  }] = useLazyQuery(SportsAccountGet);

  useEffect(() => {
    if (!userContext.roanuzMode) {
      getConfig({
        variables: { account: { key: getAccountKey() } },
      }).then((resp) => {
        if (resp
          && resp?.data?.ants_acc_sports_account_get?.sports_account?.config?.is_roanuz_mode) {
          userContext.setRoanuzMode(true);
          saveRoanuzMode(true);
        }
      });
    }
  }, [ConfigData, getConfig, userContext]);

  if (token) {
    switch (userContext.sport) {
      case AvailableSports.Cricket.key:
        secondMenu = (<CricketMenu />);
        break;
      case AvailableSports.Football.key:
        secondMenu = (<FootballMenu />);
        break;
      case AvailableSports.FantasyEditor.key:
        secondMenu = (<FantasyMenu />);
        break;
      case AvailableSports.Ecom.key:
        secondMenu = (<EcomMenu />);
        break;
      case AvailableSports.Cms.key:
        secondMenu = (<CmsMenu />);
        break;
      default:
        break;
    }
  }

  return (
    <TopBarWrapper className={className}>
      <div className="first-bar">
        <div>
          <div className="burger-menu-container">
            <BurgerMenu onClick={() => setShowPopup(true)} />
          </div>
          <Link className="logo-header" to="/">
            <IconLogo className="logo" />
            <div>
              <Text14Bold as="span">Roanuz Ants</Text14Bold>
              {Config.showSandbox && (
                <Label10Medium className="label">Sandbox</Label10Medium>
              )}
              {getAccountKey() === Config.rzAccountKey && (
                <Label10Medium className="label live">Cricket API Live Data</Label10Medium>
              )}
            </div>
          </Link>
        </div>
        <aside>
          {/* {userContext.loggedIn && (
            <IconNotification className="bell-icon" />
          )} */}
          {userContext.account && (
            <SportsDropdown user={userContext} />
          )}
          {userContext.user && userContext.user.profile && (
            <UserDropdown user={userContext} />
          )}
        </aside>
      </div>
      {secondMenu && (
        <div className="second-bar">
          <Suspense>
            {secondMenu}
          </Suspense>
        </div>
      )}
      {showPopup && (
        <div>
          <ScreenPopover
            onClose={() => { setShowPopup(false); }}
          >
            <MobileMenuWrapper>
              <Suspense>
                {secondMenu}
              </Suspense>
            </MobileMenuWrapper>
          </ScreenPopover>
        </div>
      )}
    </TopBarWrapper>
  );
}

TopBar.propTypes = {
};

const SecondaryBarWrapper = styled.div`
  background: var(--color-secondary-bg);
  height: inherit;
  border-bottom: 1px solid var(--color-table-border);
  .bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--layout-top-height);

    .pull-down-item {
      flex: ${asRem(40)} 0;
      cursor: s-resize;
      height: 100%;
      justify-content: center;
      display: flex;
      align-items: center;

      svg {
        width: ${asRem(12)};
        height: ${asRem(12)};
        margin: auto;
        display: block;
      }
    }
    .menu-items {
      flex: 1;

      .menu-bar-container {
        padding-left: 0;

        &.remove-padding {
          padding: 0;
        }
      }
    }
    .logo-header {
      display: flex;
      align-items: center;
      background: transparent;
      padding: 0;
      margin: ${asRem(10)} ${asRem(12)} ${asRem(10)} ${asRem(28)};
      margin-left: ${asRem(10)};

      .logo {
        width: ${asRem(38)};
        height: ${asRem(38)};
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        &>span {
          color: var(--color-text-1);
          display: inline-block;
          vertical-align: top;
          margin-left: ${asRem(10)};
        }
        .label {
          padding: ${asRem(2)} ${asRem(4)};
          border-radius: ${asRem(4)};
          background: #FFA134;
          color: #333;
        }
      }
    }
    aside {
      display: flex;
      margin: ${asRem(10)} ${asRem(28)};
      .bell-icon {
        display: inline-block;
        vertical-align: top;
        margin-top: ${asRem(10)};
      }
    }
  }
`;

export function SecondaryBar({ onShowPrimary }) {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const className = `active-layout-${appContext.viewState.layoutWidth} box`;

  const onMouseToggle = (enter) => {
    onShowPrimary(enter);
  };

  return (
    <SecondaryBarWrapper className={className}>
      <div className="bar">
        <div
          className="pull-down-item"
          onMouseOver={() => onMouseToggle(true)}
          onFocus={() => onMouseToggle(true)}
          onMouseOut={() => onMouseToggle(false)}
          onBlur={() => onMouseToggle(false)}
        >
          <div>
            <PullDownMenuIcon />
          </div>
        </div>
        <Link className="logo-header" to="/">
          <IconLogo className="logo" />
          <div>
            <Text14Bold as="span">Roanuz Ants</Text14Bold>
            {Config.showSandbox && (
              <Label10Medium className="label">Sandbox</Label10Medium>
            )}
          </div>
        </Link>
        <div className="menu-items">
          <MenuBar links={appContext.secondaryMenuItems} isSecondaryMenu />
        </div>
        <aside>
          {userContext.user && userContext.user.profile && (
            <UserDropdown user={userContext} />
          )}
        </aside>
      </div>
    </SecondaryBarWrapper>
  );
}

SecondaryBar.propTypes = {
  onShowPrimary: PropTypes.func,
};

const TopMenuBarWrapper = styled.div`
  .primary, .secondary {
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    transition: all 500ms cubic-bezier(0.76, 0, 0.24, 1);
    &.show, &:hover {
      top: 0;
    }
  }

  .primary {
    z-index: 1000;
    &.hover-on {
      top: 0;
    }
  }

  .secondary {
    &.show {
      --layout-nav-height: ${asRem(100)};
      .menu-items {
        .menu-bar-container {
          > a {
            &:first-child {
              padding: 0 ${asRem(12)};
              border-right: 1px solid var(--color-border-1);
              border-left: 1px solid var(--color-border-1);
              border-radius: 0;
              max-width: ${asRem(320)};
              span, p {
                color: var(--color-secondary-cta);
              }
            }
          }
        }
      }
    }
  }
`;

export function TopMenuBar() {
  const appContext = useContext(AppContext);
  const [hoverPrimaryMenu, setHoverPrimaryMenu] = useState();
  const primaryClasses = ['primary'];
  const secondarClasses = ['secondary'];
  if (appContext.showPrimaryMenu) {
    primaryClasses.push('show');
  }
  if (appContext.showSecondaryMenu) {
    secondarClasses.push('show');
  }

  primaryClasses.push(`hover-${hoverPrimaryMenu ? 'on' : 'off'}`);

  return (
    <TopMenuBarWrapper>
      <div className={primaryClasses.join(' ')}>
        <TopBar />
      </div>
      <div className={secondarClasses.join(' ')}>
        <SecondaryBar
          onShowPrimary={(show) => setHoverPrimaryMenu(show)}
        />
      </div>
    </TopMenuBarWrapper>
  );
}
