import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
  // RefField,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import { CmsStructureImageView } from './ResourceImage';
import {
  CmsStructureSearchQuery,
  CmsStructureReadQuery,
  CmsStructureCreateMutation,
  CmsStructureUpdateMutation,
  CmsStructureNameQuery,
  CmsStructureMenuQuery,
  CmsDSList,
} from './query';

const StoreTypeMap = {
  Checkbox: '_bool',

  SmallNumber: '_int',
  LargeNumber: '_int',

  Decimal: '_float',
  Price: '_float',

  Date: '_datetime',
  DateTime: '_datetime',
  Time: '_str',
};

// const LinkRefFields = ['source_def'];

function fieldsAsObject(content) {
  const newContent = content.map((item) => {
    const newItem = { ...item };
    if (newItem.options) {
      newItem.options = arrayAsObject(newItem.options);
    } else {
      newItem.options = {};
    }
    if (
      newItem.source_def
      && newItem.source_def.action
      && newItem.source_def.action.default_vals
      && (newItem.source_def.action.default_vals instanceof Array)
    ) {
      // TODO: May be right way to do is change
      // ActionFormItem to handle array
      const newObj = {};
      newItem.source_def.action.default_vals.forEach((val) => {
        newObj[val.key] = val.value;
      });
      newItem.source_def.action.default_vals = newObj;
    }
    return newItem;
  });
  return arrayAsObject(newContent);
}

function dataAsObject(content) {
  const newContent = content.map((item) => {
    const newItem = { ...item };
    newItem.fields = newItem.fields ? fieldsAsObject(newItem.fields) : {};
    return newItem;
  });
  return arrayAsObject(newContent);
}

function fieldAsArray(content) {
  let newContent = objectAsArray(content);
  newContent = newContent.map((item) => {
    const newItem = { ...item };
    if (newItem?.source_def?.structure) {
      newItem.source_def.structure = { _hashkey: newItem.source_def.structure.hashkey };
    }
    // if (
    //   newItem.source_def
    //   && newItem.source_def.action
    //   && newItem.source_def.action.default_vals
    //   && !(newItem.source_def.action.default_vals instanceof Array)
    // ) {
    //   const newItems = [];
    //   Object.keys(newItem.source_def.action.default_vals).forEach((key) => {
    //     newItems.push({ key, value: newItem.source_def.action.default_vals[key] });
    //   });
    //   newItem.source_def.action.default_vals = newItems;
    // }

    if (newItem.options) {
      newItem.options = objectAsArray(newItem.options);
    } else {
      newItem.options = [];
    }

    let resolvedType = '_str';
    if (StoreTypeMap[newItem.editor_type]) {
      resolvedType = StoreTypeMap[newItem.editor_type];
    }
    newItem.store_type = resolvedType;

    return newItem;
  });
  return newContent;
}

function dataAsArray(content) {
  let newContent = objectAsArray(content);
  newContent = newContent.map((item) => {
    const newItem = { ...item };
    newItem.fields = newItem.fields ? fieldAsArray(newItem.fields) : [];
    return newItem;
  });
  return newContent;
}

export const CmsStructureResource = Resource({
  resourceId: 'structure',
  app: 'cms',
  name: 'Structure',
  keyVal: 'CmsStructure',
  topicName: 'CmsStructure',
  storeId: 'CmsStructure',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Structure',
      shape: Yup.object().shape({
        name: StringFieldMinMax(2, 120),
        groups: Yup.mixed(),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<CmsStructureImageView item={item} />),
  queries: {
    read: QueryAction({
      query: CmsStructureReadQuery,
      resourceNamePath: 'item.structure.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: CmsStructureSearchQuery, responsePath: 'cms_structure_search' }),
    search: QueryAction({
      query: CmsStructureSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.name',
    }),
    menu: QueryAction({
      query: CmsStructureMenuQuery,
      responsePath: 'cms_structure_content_menu',
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.name',
    }),
    name: QueryAction({ query: CmsStructureNameQuery, resourceNamePath: 'cms_structure_name.name' }),
    dsList: QueryAction({ query: CmsDSList, responsePath: 'cms_ds_list' }),
  },
  mutations: {
    update: MutationAction({
      mutation: CmsStructureUpdateMutation,
      cs: ['structure'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        if (!resp.structure.groups) {
          resp.structure.groups = [{ fields: [] }];
        }
        // resp.structure.groups = resp.structure.groups.map((x) => {
        //   return {
        //     ...x,
        //     fields: arrayAsObject(x.fields),
        //   };
        // });
        // resp.structure.groups = arrayAsObject(
        //   resp.structure.groups,
        // );
        resp.structure.groups = dataAsObject(resp.structure.groups);
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.structure.groups = dataAsArray(resp.structure.groups);
        resp.structure.groups = resp.structure.groups.map((group) => {
          return {
            ...group,
            fields: group.fields.map((field, index) => {
              return {
                ...field,
                key: field.key !== '' ? field.key : index.toString(),
              };
            }),
          };
        });
        // resp.structure.groups = objectAsArray(resp.structure.groups);
        // Object.keys(resp.structure.groups).forEach((key) => {
        //   resp.structure.groups[key].fields = objectAsArray(resp.structure.groups[key].fields);
        //   Object.keys(resp.structure.groups[key].fields).forEach((fkey) => {
        //     const afield = resp.structure.groups[key].fields[fkey];
        //     let resolvedType = '_str';
        //     if (StoreTypeMap[afield.editor_type]) {
        //       resolvedType = StoreTypeMap[afield.editor_type];
        //     }
        //     afield.store_type = resolvedType;
        //   });
        // });
        // resp.structure = {
        //   active: resp.structure.active,
        //   name: resp.structure.name,
        //   groups: resp.structure.groups,
        // };
        console.log('Saving', resp);
        return resp;
      },
    }),
    create: MutationAction({ mutation: CmsStructureCreateMutation }),
  },
});
