import React, { Suspense } from 'react';
import { Breadcrumb } from 'core/Breadcrumb';

import { CatgoryRoutes } from './category/routes';
import { InventoryRoutes } from './inventory/routes';
import { PriceRoutes } from './price/routes';
import { ProductRoutes } from './product/routes';
import { ProductKindRoutes } from './productKind/routes';
import { ProductSpecGroupRoutes } from './productSpecGroup/routes';
import { ProductSpecRoutes } from './productSpec/routes';

export function EcomRoutes() {
  return (
    <Suspense fallback={<>...</>}>
      <Breadcrumb />
      <CatgoryRoutes />
      <InventoryRoutes />
      <PriceRoutes />
      <ProductRoutes />
      <ProductKindRoutes />
      <ProductSpecGroupRoutes />
      <ProductSpecRoutes />
    </Suspense>
  );
}
