/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  // StringFieldMinMax,
  // RefField,
} from 'ants/resource';
// import { arrayAsObject, objectAsArray } from 'lib/utils';
import { CmsStructureContentImageView } from './ResourceImage';
import {
  CmsStructureContentSearchQuery,
  CmsStructureContentReadQuery,
  CmsStructureContentCreateMutation,
  CmsStructureContentUpdateMutation,
  CmsStructureContentNameQuery,
} from './query';
import { contentItemDataForSave } from './helper';

export const parseValues = (values) => {
  if (!values) {
    return;
  }
  Object.entries(values?.props ? values?.props : values).forEach(([key, value]) => {
    if (value?.typ === 'Data' && !value?.data?.value) {
      values.props[key] = null;
    }
    if (value?.data?.structure?.hashkey) {
      value.data.structure._hashkey = value?.data?.structure?.hashkey;
      delete value?.data?.structure.hashkey;
    }
    if (value?.data?.value?.structure?.content_key?.hashkey) {
      value.data.value.structure.content_key._hashkey = value?.data?.value?.structure?.content_key?.hashkey;
      delete value?.data?.value?.structure?.content_key?.hashkey;
    }

    if (typeof value === 'object') {
      parseValues(value);
    }
  });
};

export const CmsStructureContentResource = Resource({
  resourceId: 'structure_content',
  app: 'cms',
  name: 'StructureContent',
  pageKey: 'cms.structure_content.sc',
  keyVal: 'CmsStructureContent',
  topicName: 'CmsStructureContent',
  storeId: 'CmsStructureContent',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Content',
      shape: Yup.object().shape({
        // name: StringFieldMinMax(3, 120),
        // groups: Yup.array().of(Yup.object().shape({
        //   fields: Yup.array().of(Yup.object().shape({
        //     key: StringFieldMinMax(1, 120),
        //   })).nullable().default([]),
        // })).nullable().default([]),
      }),

    }),
    // ColumnSet({
    //   name: 'Desc',
    //   shape: Yup.object().shape({
    //     desc: Yup.string().max(1024, 'Too big').nullable().default(''),
    //     image: Yup.string(),
    //   }),
    // }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<CmsStructureContentImageView item={item} />),
  queries: {
    read: QueryAction({
      query: CmsStructureContentReadQuery,
      resourceNamePath: 'item.name.internal_name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: CmsStructureContentSearchQuery, responsePath: 'cms_structure_content_search' }),
    search: QueryAction({
      query: CmsStructureContentSearchQuery,
      // responsePath: 'cms_structure_content_search',
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.internal_name',
    }),
    name: QueryAction({ query: CmsStructureContentNameQuery, resourceNamePath: 'cms_structure_content_name.name' }),
  },
  mutations: {
    update: MutationAction({
      mutation: CmsStructureContentUpdateMutation,
      cs: ['content'],
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        // console.log('save', resource, data);
        resp.content.data = contentItemDataForSave(data.content.data);
        // resp.structure = resource.structure;
        return resp;
      },
    }),
    create: MutationAction({
      mutation: CmsStructureContentCreateMutation,
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.content.data = contentItemDataForSave(data.content.data);
        return resp;
      },
    }),
  },
});
