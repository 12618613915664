import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { setValueOnPath, dataFromPath } from 'lib/utils';

export const DeletedValueStarts = '!!DELETED!!';
export const DeletedValueEnds = '!!DELETED!!';
export const NoneSelectValue = '!!NONE!!';

export function isDeletedValue(val) {
  return (val
    && typeof val === 'string'
    && val.startsWith(DeletedValueStarts)
    && val.endsWith(DeletedValueEnds)) === true;
}

export function getDeletedValue(val) {
  let newValue = val.slice(DeletedValueStarts.length);
  if (newValue) {
    newValue = newValue.slice(0, newValue.length - DeletedValueEnds.length);
    if (newValue.startsWith('{') && newValue.endsWith('}')) {
      return JSON.parse(newValue);
    }
    return newValue;
  }
  return newValue;
}

export function createDeletedValue(val) {
  if (typeof val === 'string') {
    return `${DeletedValueStarts}${val}${DeletedValueEnds}`;
  }
  return `${DeletedValueStarts}${JSON.stringify(val)}${DeletedValueEnds}`;
}

export function removeDeletedValues(obj) {
  if (!obj) return obj;
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    let val = obj[key];
    if (isDeletedValue(val)) {
      val = undefined;
    }
    if (val === NoneSelectValue) {
      val = undefined;
    }
    if (val === undefined) return;
    newObj[key] = obj[key];
    if (obj[key] && typeof obj[key] === 'object') {
      newObj[key] = removeDeletedValues(obj[key]);
    }
  });
  return newObj;
}

const defaultContext = {
  isSubmitting: false,
  dirty: false,
  values: {},
  fieldStates: {}, // {touched: true, focus: true}
  errors: null,
  errorFields: {},
  isValid: false,
  initValidated: false,
  dragContext: {
    kind: null,
    context: null,
  },
};

export const FormContext = React.createContext({ ...defaultContext });
export const FormContextConsumer = FormContext.Consumer;

export function FormContextProvider({
  initialValues,
  validationSchema,
  validationContext = null,
  onSubmit,
  onChange = null,
  children,
}) {
  const validate = (state, values) => {
    try {
      const vals = values || state.values;
      state.validationSchema.validateSync(vals, {
        abortEarly: false,
        context: {
          initialValues: state.initialValues,
          context: state.validationContext,
        },
      });
      if (onChange) {
        onChange({ values: vals });
      }
      return {
        ...state,
        errors: null,
        errorFields: {},
        isValid: true,
        initValidated: true,
      };
    } catch (errors) {
      // console.log('Er', JSON.parse(JSON.stringify(error)));
      const errorFields = {};
      errors.inner.forEach((errorItem) => {
        const fpath = errorItem.params.path;
        let isError = true;
        const formValue = dataFromPath(values, fpath);
        const isLeft = state.fieldStates[fpath]?.left ?? false;
        const isEmpty = formValue === '' || formValue === null || formValue === undefined;
        if (isEmpty && (!isLeft) && state.submitReqCount === 0) {
          isError = false;
        }
        errorFields[fpath] = isError;
      });

      return {
        ...state,
        errors,
        errorFields,
        isValid: false,
        initValidated: true,
      };
    }
  };
  const castValues = validationSchema.cast({ ...initialValues }, { assert: false });

  const [formContext, setFormContext] = useState({
    setValues: (values) => {
      setFormContext((state) => ({
        ...state,
        values,
        initValidated: true,
      }));
    },
    setValuesErrors: (values, errors) => {
      setFormContext((state) => ({
        ...state,
        values,
        errors,
        initValidated: true,
      }));
    },
    setErrors: (errors) => {
      setFormContext((state) => ({
        ...state,
        errors,
        isValid: !errors,
        initValidated: true,
      }));
    },
    setSubmitting: (newState) => {
      setFormContext((state) => ({
        ...state,
        isSubmitting: newState,
      }));
    },
    validate(values) {
      setFormContext((state) => {
        return validate(state, values);
      });
    },
    setFieldValue(name, value) {
      setFormContext((state) => {
        const newValues = setValueOnPath({ ...state.values }, value, name, true);
        const updatedValuesRaw = JSON.stringify(newValues);
        // console.log('rz/form/Setting values', name, value, newValues, updatedValuesRaw);
        return validate({
          ...state,
          values: newValues,
          dirty: state.valuesRaw !== updatedValuesRaw,
        });
      });
    },
    setDragContext(kind, context) {
      setFormContext((state) => {
        return validate({
          ...state,
          dragContext: {
            kind, context,
          },
        });
      });
    },
    markTouched(name, touched) {
      setFormContext((state) => ({
        ...state,
        fieldStates: {
          ...state.fieldStates,
          [name]: {
            ...(state.fieldStates[name] || {}),
            touched,
          },
        },
      }));
    },
    markLeft(name, left) {
      setFormContext((state) => ({
        ...state,
        fieldStates: {
          ...state.fieldStates,
          [name]: {
            ...(state.fieldStates[name] || {}),
            left,
          },
        },
      }));
    },
    formSaved() {
      setFormContext((state) => {
        const updatedValuesRaw = JSON.stringify(state.values);
        return {
          ...state,
          valuesRaw: updatedValuesRaw,
          dirty: false,
        };
      });
    },
    resetValidation(newInitValues) {
      setFormContext((state) => {
        const newCastValue = validationSchema.cast({ ...newInitValues }, { assert: false });
        return {
          ...state,
          values: {
            ...newCastValue,
          },
          valuesRaw: JSON.stringify(castValues),
          initialValues: newInitValues,
          initValidated: false,
        };
      });
    },
    submitForm(params) {
      let ts = (new Date()).getTime();
      setFormContext((state) => {
        // To handle this bug https://github.com/facebook/react/issues/12856#issuecomment-390206425
        if (state.lastSubmittedTs >= ts) {
          return state;
        }

        const updatedState = {
          ...state,
          submitReqCount: state.submitReqCount + 1,
          lastSubmittedTs: ts,
        };

        ts = 0;
        const newState = validate(updatedState);
        if (!newState.errors) {
          // onSubmit(state.values, params, newState);
          state.onSubmit(state.values, params, newState);
        } else {
          console.log('Form Errors', newState.errors);
        }
        if (params?.target) {
          params.preventDefault();
        }
        return newState;
      });
    },
    ...defaultContext,
    initialValues,
    validationContext,
    values: {
      ...castValues,
    },
    fieldStates: {},
    submitReqCount: 0,
    lastSubmittedTs: 0,
    valuesRaw: JSON.stringify(castValues),
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!formContext.initValidated) {
      let done = false;
      Object.keys(formContext.values).forEach((key) => {
        if (done) return;
        const val = formContext.values[key];
        if (typeof val === 'object' && val !== null) {
          Object.keys(formContext.values[key]).forEach((subKey) => {
            if (done) return;
            if (formContext.values[key][subKey] !== null && formContext.values[key][subKey] !== '') {
              formContext.validate();
              done = true;
            }
          });
        } else if (val !== null && val !== '') {
          formContext.validate();
          done = true;
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.initValidated]);

  useEffect(() => {
    setFormContext((state) => {
      return {
        ...state,
        onSubmit,
      };
    });
  }, [onSubmit]);

  return (
    <FormContext.Provider value={formContext}>
      {children}
    </FormContext.Provider>
  );
}

FormContextProvider.propTypes = {
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  children: PropTypes.element,
};

export function FormBoundary({
  children,
  ...props
}) {
  return (
    <FormContextProvider {...props}>
      <FormContextConsumer>
        {(values) => children(values)}
      </FormContextConsumer>
    </FormContextProvider>
  );
}

FormBoundary.propTypes = {
  ...FormContextProvider.propTypes,
  children: PropTypes.func,
};

export function Form({ children }) {
  const formContext = useContext(FormContext);
  return (
    <form
      onSubmit={formContext.submitForm}
    >
      {children}
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
