import { createGlobalStyle } from 'styled-components';
import { asRem } from 'lib/css';

export const GlobaVariables = createGlobalStyle`

:root {
  --font-family: 'DM Sans', sans-serif;
  --font-numbers: sans-serif;


  --color-primary-bg: #0D1116;
  --color-secondary-bg: #161C23;

  --color-text-1: #fff;
  --color-text-2: #C5C5C5;
  --color-text-3: #41454A;
  --color-text-rev: #11161B;
  --color-button-text-1: #333333;
  --color-button-text-2: var(--color-text-rev);
  --color-label: var(--color-text-2);
  --color-image-placeholder-text: var(--color-text-rev);
  --color-border-1: #212932;
  --color-border-2: #303943;
  --color-border-3: #232931;
  --color-table-border: #21252A;
  --color-button-primary-bg: #F5FF78;
  --color-button-primary-bg-overlay: rgba(245, 255, 120, 0.05);
  --color-disabled: #212932;
  --color-disabled-text: #4F5B68;
  --color-highlight: #FF95E8;
  --color-highlight-1: #F2DB7E;
  --color-highlight-2: #A58EFF;
  --color-error: #F58684;
  --color-error-2: #FF7675;
  --color-error-2-overlay: #FF76751A;
  --color-website-btn: #D2FD49;
  --color-website-btn-text: #11161B;
  ${'' /* --color-error-overlay: rgba(245, 134, 132, 0.04); */}
  --color-error-overlay: #1F2027;
  --color-secondary-cta: #80A9D7;
  --color-button-3-bg: #15202E;
  --color-overlay: rgb(13 17 22 / 75%);
  --color-realtime: orange;
  --color-realtime-me: #FF95E8;
  --color-draft:  #989899;
  --color-published: green;
  --color-image-placeholder-bg: #80A9D7;
  --color-active-status: #00A656;
  --color-offline-status: #F4B740;
  --color-alert: #C83739;
  --color-helpCenter: #4D3CB4;
  --color-helpCenter-2: #7B818B;

  --color-au-1: #EC2761;
  --color-au-2: #CBA312;
  --color-au-3: orange;
  --color-au-4: red;

  --color-runs-normal: var(--color-image-placeholder-bg);
  --color-runs-extra: #FCF9AB;
  --color-runs-four: #D96FFF;
  --color-runs-six: #73FF82;
  --color-runs-wicket: #FF6590;

  --layout-top-height: ${asRem('58')};
  --layout-nav-height: ${asRem('106')};
  --layout-nav-sec-height: ${asRem('58')};
  --layout-left-width: ${asRem('200')};
  --layout-right-width: ${asRem('200')};
  --layout-bottom-height: ${asRem('54')};
  --layout-lg-max-width: ${asRem('660')};
  --layout-md-max-width: ${asRem('620')};
  --layout-sm-max-width: ${asRem('420')};
  --layout-xs-max-width: ${asRem('320')};


  --layout-padding: ${asRem('70')};
  --layout-padding-box: ${asRem('20')};
  --layout-padding-contained: ${asRem('15')};
  --layout-border-radius: ${asRem('20')};

  --layout-container-width-1: ${asRem(908)};
  --layout-container-width-2: ${asRem(1120)};
  --layout-container-width-3: ${asRem(1008)};
  --layout-fullpage-padding: ${asRem(28)};
  --layout-container-box-width: ${asRem(620)};
  --layout-dailog-box-min-width: ${asRem(300)};
  --layout-dailog-box-max-width: ${asRem(620)};


  /* Fonts */
  --display-xl-fontSize: ${asRem('40')};
  --display-lg-fontSize: ${asRem('34')};
  --display-md-fontSize: ${asRem('24')};
  --display-sm-fontSize: ${asRem('20')};
  --display-xs-fontSize: ${asRem('18')};
  --text-lg-fontSize: ${asRem('16')};
  --text-md-fontSize: ${asRem('14')};
  --label-lg-fontSize: ${asRem('12')};
  --label-md-fontSize: ${asRem('10')};
  --label-sm-fontSize: ${asRem('8')};
  
  /* Line Height */
  --display-xl-lineHeight: ${asRem('40')};
  --display-lg-lineHeight: ${asRem('44')};
  --display-md-lineHeight: ${asRem('31')};
  --display-sm-lineHeight: ${asRem('26')};
  --display-xs-lineHeight: ${asRem('23')};
  --text-lg-lineHeight: ${asRem('21')};
  --text-md-lineHeight: ${asRem(20)};
  --label-lg-lineHeight: ${asRem('16')};
  --label-md-lineHeight: ${asRem('13')};
  --label-sm-lineHeight: ${asRem('10')};

  /* Font weights */

  --regular-fontWeight: 400;
  --medium-fontWeight: 500;
  --semibold-fontWeight: 600;
  --bold-fontWeight: 700;


  --color-t2-primary-bg: #efefef;
  --color-t2-primary-bg: #e4edff;
  --color-t2-primary-bg: #fff;
  --color-t2-button-primary-bg: #980D55;
  --color-t2-secondary-cta: #0057B8;
  --layout-t2-box-radius: ${asRem(10)};
  --layout-t2-box-margin: ${asRem(10)};

  --color-t2-primary-bg: var(--color-primary-bg);
  --color-t2-button-primary-bg: var(--color-button-primary-bg);
  --color-t2-secondary-cta: var(--color-secondary-cta);
}
`;
