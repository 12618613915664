import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
  RefField,
} from 'ants/resource';
import {
  editorMetaPrepareForEdit,
  editorMetaPrepareForSave,
  arrayAsObjectFromMeta,
  objectAsArrayFromMeta,
  editorMetaCleanUp,
} from 'core/editorMeta';
import { contentSourceValueItemForSave } from 'cms/structureContent/helper';
import { CmsPageImageView } from './ResourceImage';
import {
  CmsPageSearchQuery,
  CmsPageReadQuery,
  CmsPageCreateMutation,
  CmsPageUpdateMutation,
  CmsPageNameQuery,
} from './query';

const LocalFields = ['localFieldBodyType'];
const ResourceSourceTypes = ['Image'];
const RawSourceTypes = ['Raw', 'ClientView', 'Outlet'];

function contentAsObject(content, metaMap) {
  return arrayAsObjectFromMeta({
    val: content,
    metaMap,
    childrenField: 'children',
    newObjFunc: (item) => {
      const newItem = { ...item };
      // if (ResourceSourceTypes.includes(newItem.source_type)) {
      //   if (newItem.resource_key) {
      //     newItem.resource_key = { hashkey: newItem.resource_key };
      //   }
      // } else {
      //   newItem.resource_key = null;
      // }

      if (newItem.structure && newItem.structure.hashkey) {
        newItem.structure = { hashkey: newItem.structure.hashkey };
      }

      return newItem;
    },
  });
}

function sourceDefAndValueForSave(item) {
  if (!item || !item.source_def) {
    return {};
  }

  const sourceDef = { ...item.source_def };
  let sourceVal = { ...item.source_val };

  if (sourceDef.typ === 'Structure') {
    sourceDef.action = null;
    if (sourceDef.structure && sourceDef.structure.hashkey) {
      sourceDef.structure = {
        _hashkey: sourceDef.structure.hashkey,
      };
      const newSrcValue = contentSourceValueItemForSave(sourceVal);
      sourceVal = {
        ...newSrcValue,
        typ: sourceDef.typ,
        structure: sourceDef.structure,
      };
    }
  } else if (sourceDef?.typ === 'Action') {
    sourceDef.structure = null;
    const newSrcValue = contentSourceValueItemForSave(sourceVal);
    sourceVal = {
      ...newSrcValue,
      typ: sourceDef.typ,
      action_key: sourceDef?.action?.key,
    };
  }

  return {
    source_def: sourceDef,
    source_val: sourceVal.typ ? sourceVal : null,
  };
}

function contentAsArray(content, metaMap) {
  return objectAsArrayFromMeta({
    val: content,
    metaMap,
    childrenField: 'children',
    newObjFunc: (item) => {
      let newItem = { ...item };
      if (!newItem.attrs || Object.keys(newItem.attrs).length === 0) {
        newItem.attrs = null;
      }

      newItem = {
        ...newItem,
        ...sourceDefAndValueForSave(newItem),
      };

      if (newItem?.source_type === 'Media') {
        if (newItem.data) {
          newItem.data = {
            typ: newItem.data.typ ?? 'Image',
            hashkey: newItem.data.hashkey || newItem.data,
          };
        } else {
          newItem.data = null;
        }
      }

      if (newItem.source_type === 'ClientView') {
        if (newItem.data && newItem.data.viewid) {
          newItem.data = {
            typ: 'ClientView',
            viewid: newItem.data.viewid,
          };
        } else {
          newItem.data = null;
        }
      }

      if (!ResourceSourceTypes.includes(newItem.source_type)) {
        // newItem.resource_key = null;
      }

      if (!RawSourceTypes.includes(newItem.source_type)) {
        newItem.raw = '';
      }

      if (newItem.source_type !== 'Div') {
        newItem.children = [];
      }

      // newItem.children = newItem.children ? contentAsArray(newItem.children, forSave) : {};
      return newItem;
    },
  });
}

export function parseNestedData(obj) {
  Object.entries(obj).forEach(([key, value]) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      if (value?.data) {
        // eslint-disable-next-line no-param-reassign
        obj[key] = { ...value.data };
        parseNestedData(obj[key]);
      } else {
        parseNestedData(value);
      }
    }
  });
}

export const CmsPageResource = Resource({
  resourceId: 'page',
  app: 'cms',
  name: 'page Page',
  keyVal: 'CmsPage',
  topicName: 'CmsPage',
  storeId: 'CmsPage',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Page',
      shape: Yup.object().shape({
        name: StringFieldMinMax(2, 120),
        base_page: RefField(),
      }),
      referenceFields: ['base_page'],
    }),
    ColumnSet({
      name: 'Body',
      shape: Yup.object().shape({
        // by_structure: RefField(),
        // by_structure_content: RefField(),
      }),
      // referenceFields: ['by_structure', 'by_structure_content'],
    }),
    ColumnSet({
      name: 'EditorMeta',
      viewKey: 'editor_meta',
      shape: Yup.object().shape({
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<CmsPageImageView item={item} />),
  queries: {
    read: QueryAction({
      query: CmsPageReadQuery,
      resourceNamePath: 'item.page.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: CmsPageSearchQuery, responsePath: 'cms_page_search' }),
    search: QueryAction({
      query: CmsPageSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'page.name',
    }),
    name: QueryAction({ query: CmsPageNameQuery, resourceNamePath: 'cms_page_name.name' }),
  },
  mutations: {
    update: MutationAction({
      mutation: CmsPageUpdateMutation,
      cs: ['page'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        // console.log('Before Save', JSON.parse(JSON.stringify(resp)));
        return resp;
      },
    }),
    updateContent: MutationAction({
      mutation: CmsPageUpdateMutation,
      responsePath: 'cms_page_update',
      cs: ['body', 'editor_meta'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        if (!resp.body.content) {
          resp.body.content = [];
        }
        // console.log(
        //   'Before Edit parse',
        //   JSON.parse(JSON.stringify(resp.body.content)),
        //   JSON.parse(JSON.stringify(resp.editor_meta.content)),
        // );
        resp.editor_meta.content = editorMetaPrepareForEdit(resp.editor_meta.content);
        resp.body.content = contentAsObject(
          resp.body.content,
          resp.editor_meta.content,
        );
        // console.log(
        //   'Editing',
        //   JSON.parse(JSON.stringify(resp.body.source_def)),
        //   JSON.parse(JSON.stringify(resp.body.source_val)),
        //   JSON.parse(JSON.stringify(resp.body.content)),
        //   JSON.parse(JSON.stringify(resp.editor_meta.content)),
        // );
        return resp;
      },
      prepareForSave(self, resource, data) {
        const { version } = data;
        const resp = self.defaultPrepareForSave(self, resource, data);

        LocalFields.forEach((field) => {
          resp.body[field] = undefined;
        });
        // console.log('Save Before cleanup', JSON.parse(JSON.stringify(resp.body.content)));
        if (version) {
          const [newContent, newMetaMap] = editorMetaCleanUp(
            resp.body.content,
            resp.editor_meta.content,
          );
          resp.body.content = newContent;
          resp.editor_meta.content = newMetaMap;
        }
        resp.body.content = contentAsArray(resp.body.content, resp.editor_meta.content);
        // console.log('After Prepare', JSON.parse(JSON.stringify(resp.body.content)));
        resp.editor_meta.content = editorMetaPrepareForSave(resp.editor_meta.content);
        if (resp.body.source_def && resp.body.source_def.structure) {
          resp.body = {
            ...resp.body,
            ...sourceDefAndValueForSave(resp.body),
          };
        }
        return resp;
      },
    }),
    create: MutationAction({ mutation: CmsPageCreateMutation }),
  },
});
