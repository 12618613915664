import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { ProductInventorySourceImageView } from './ResourceImage';
import {
  ProductInventorySourceSearchQuery,
  ProductInventorySourceReadQuery,
  ProductInventorySourceCreateMutation,
  ProductInventorySourceUpdateMutation,
  ProductInventorySourceNameQuery,
} from './query';

export const ProductInventorySourceResource = Resource({
  resourceId: 'inventory',
  app: 'ecom',
  name: 'Product Inventory',
  keyVal: 'ProductInventorySource',
  topicName: 'ProductInventorySource',
  storeId: 'ProductInventorySource',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Source',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<ProductInventorySourceImageView item={item} />),
  queries: {
    read: QueryAction({
      query: ProductInventorySourceReadQuery,
      resourceNamePath: 'item.source.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: ProductInventorySourceSearchQuery, responsePath: 'ecom_inventory_search' }),
    search: QueryAction({
      query: ProductInventorySourceSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'source.name',
    }),
    name: QueryAction({ query: ProductInventorySourceNameQuery, resourceNamePath: 'ecom_inventory_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: ProductInventorySourceUpdateMutation, cs: ['source'] }),
    create: MutationAction({ mutation: ProductInventorySourceCreateMutation }),
  },
});
