import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Breadcrumb } from 'core/Breadcrumb';
import { Pages } from 'core/pages';

import { AssociationRoutes } from './association/routes';
import { RegionRoutes } from './region/routes';
import { CricketRoutes } from './cricket/routes';
import { StadiumRoutes } from './stadium/routes';
import { CatgoryRoutes } from './category/routes';
import { ClubRoutes } from './club/routes';
import { TeamRoutes } from './team/routes';

const UpdateTags = lazy(() => import('./tagsAndProperties/Tags'));
const UpdateProperties = lazy(() => import('./tagsAndProperties/Properties'));

export function SportsRoutes() {
  return (
    <Suspense fallback={<>...</>}>
      <Breadcrumb />
      <AssociationRoutes />
      <RegionRoutes />
      <CricketRoutes />
      <StadiumRoutes />
      <CatgoryRoutes />
      <ClubRoutes />
      <TeamRoutes />
      <Routes>
        <Route
          path={Pages.sports.tags.meta.path}
          element={(<UpdateTags />)}
        />
        <Route
          path={Pages.sports.props.meta.path}
          element={(<UpdateProperties />)}
        />
      </Routes>
    </Suspense>
  );
}
