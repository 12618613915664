import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { ProductImageView } from './ResourceImage';
import {
  ProductSearchQuery,
  ProductReadQuery,
  ProductCreateMutation,
  ProductUpdateMutation,
  ProductNameQuery,
} from './query';

export const ProductResource = Resource({
  resourceId: 'product',
  app: 'ecom',
  name: 'Product',
  keyVal: 'Product',
  topicName: 'Product',
  storeId: 'Product',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Name',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
      }),
    }),
    ColumnSet({
      name: 'Product',
      shape: Yup.object().shape({
        sku: StringFieldMinMax(3, 120),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<ProductImageView item={item} />),
  queries: {
    read: QueryAction({
      query: ProductReadQuery,
      resourceNamePath: 'item.name.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: ProductSearchQuery, responsePath: 'ecom_product_search' }),
    search: QueryAction({
      query: ProductSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.name',
    }),
    name: QueryAction({ query: ProductNameQuery, resourceNamePath: 'ecom_product_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: ProductUpdateMutation, cs: ['name', 'product'] }),
    create: MutationAction({ mutation: ProductCreateMutation }),
  },
});
