import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { asRem, Breakpoint } from 'lib/css';
import { Text16Bold } from 'style/typography';
import {
  // SlideDownKF
  BackInDown,
} from 'style/keyframes';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/icon-arrow-right.svg';
import { getToggleState, setToggleState } from './localStore';

const BoxWrapper = styled.div`
  margin-bottom: var(--layout-padding-box);
  border: 1px solid var(--color-border-1);
  background: var(--color-secondary-bg);
  border-radius: var(--layout-border-radius);
  padding: ${asRem(16)};

  @media screen and (min-width: ${Breakpoint.sm}) {
    padding: var(--layout-padding-box);
  }

  input, .input-proxy {
    width: 100%;
  }
  input[type=checkbox]{
    width: var(--checkbox-height);

    &.checkbox-large {
      width: ${asRem(42)};
    }
  }

  &.nopad {
    padding: 0;
  }

  &.center {
    max-width: var(--layout-md-max-width);
    width: 100%;
    margin: auto;
    margin-bottom: var(--layout-padding-box);
    
    &:last-child {
      margin-bottom: 0;
    }
  }


  .flex-row {
    @media screen and (min-width: ${Breakpoint.sm}) {
      display: flex;
      >div {
        flex: 1;
        margin-right: var(--layout-padding-contained);

        &:last-child {
          margin-right: 0;
        }
      }
      >button {
        height: fit-content;
        margin: auto 0;
      }
      &:last-child {
        &>div {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export function Box({
  children, center = false, className,
}) {
  const classes = ['rz-box'];
  if (className) {
    classes.push(className);
  }
  if (center) {
    classes.push('center');
  }

  return (
    <BoxWrapper className={classes.join(' ')}>
      {children}
    </BoxWrapper>
  );
}

Box.propTypes = {
  children: PropTypes.any,
  center: PropTypes.bool,
  className: PropTypes.string,
};

Box.TitleWrapper = styled.div`
  margin-bottom: var(--layout-padding-box);
  &.center {
    text-align: center;
  }
  > div {
    display: flex;
    align-items: center;
  }
  h4 {
    color: var(--color-text-1);
    a {
      padding: 0;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }
  }
  p {
    color: var(--color-label);
    padding: var(--layout-padding-contained);
    padding-left: 0;
    font-style: italic;
    font-size: ${asRem(14)};
  }
`;

Box.Title = function BoxTitle({
  aside, children: title, center = false, note,
  onClick = null,
  className = null,
}) {
  const classes = className ? [className] : [];
  classes.push('rz-box-title');
  if (center) {
    classes.push('center');
  }
  if (!title && !aside) return null;

  return (
    <Box.TitleWrapper className={classes.join(' ')} onClick={onClick}>
      <div>
        <ArrowRightIcon />
        <Text16Bold as="h4">
          {title}
        </Text16Bold>
      </div>
      {aside && (
        <aside>{aside}</aside>
      )}
      {note && (
        <p>{note}</p>
      )}
    </Box.TitleWrapper>
  );
};

Box.Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  aside: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  note: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  center: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Box.ContentWrapper = styled.div`
  h4 {
    font-size: ${asRem(16)};
    line-height: ${asRem(21)};
    font-weight: bold;
  }
`;

Box.Content = function BoxContent({ className, children, nodeRef }) {
  const classes = ['rz-box-content'];
  if (className) classes.push(className);
  return (
    <Box.ContentWrapper className={classes.join(' ')} ref={nodeRef}>
      {children}
    </Box.ContentWrapper>
  );
};

Box.Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  nodeRef: PropTypes.any,
};

Box.CollapsibleWrapper = styled.div`

.rz-box {
  overflow: hidden;
}

.rz-box-title {
  padding: var(--layout-padding-box);
  margin-bottom: 0;
  background-color: var(--color-secondary-bg);
  /* border-radius: var(--layout-border-radius); */
  cursor: pointer;
  position: relative;
  z-index: 11;

  h4 {
    font-weight: 500;
    font-size: ${asRem(14)};
    line-height: ${asRem(18)};
    color: var(--color-text-2);
  }

  svg {
    width: ${asRem(18)};
    line-height: ${asRem(18)};
    fill: currentColor;
    margin-right: ${asRem(4)};
    display: inline-block;
    transform: rotate(90deg);
    transition: all 500ms ease-in-out;
  }

  &.with-action {
    display: flex;
    justify-content: space-between;
    aside {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.rz-box-content {
  border-top: 1px solid var(--color-border-1);
  margin: 0 var(--layout-padding-box);
  padding-top: var(--layout-padding-box);
  >div {
    &:last-child {
      margin-bottom: var(--layout-padding-box);
    }
  }
}

&.state-close {
  .rz-box-title {
    svg {
      transform: rotate(0);
    }
  }
}

&.no-title {
  .rz-box-content {
    border-top: none;
  }
}

.showhide-enter {
  animation: ${BackInDown} 500ms linear;
}

.showhide-exit {
  animation: ${BackInDown} 500ms linear reverse;
  ${'' /* animation: ${SlideDownKF} 500ms linear reverse; */}
}

.showhide-exit-done {
  opacity: 0;
  max-height: 0;
}
`;

Box.Collapsible = function BoxCollapsible({
  className,
  title,
  renderTitle,
  // renderOnlyAfterOpen = false,
  rememberKey = null,
  open = true,
  children,
  renderAction = null,
}) {
  const [openState, setOpenState] = useState(getToggleState(rememberKey, open));

  const updateOpenState = (state) => {
    setToggleState(rememberKey, state);
    setOpenState(state);
  };

  const classes = [
    `state-${openState ? 'open' : 'close'}`,
  ];
  if (className) classes.push(className);

  // let contentShouldRender = true;
  // if (renderOnlyAfterOpen && !openState) {
  //   contentShouldRender = false;
  // }

  // console.log('Sho', contentShouldRender);

  // https://github.com/reactjs/react-transition-group/blob/1fd4a65ac45edd2aea3dec18eeb8b9c07c7eb93f/CHANGELOG.md#features
  const nodeRef = React.useRef(null);
  const hasTitle = title || renderTitle;

  if (!hasTitle) classes.push('no-title');

  return (
    <Box.CollapsibleWrapper className={classes.join(' ')}>
      <Box className="nopad">
        {hasTitle && (
          <Box.Title
            onClick={() => updateOpenState(!openState)}
            aside={renderAction ? renderAction({ openState }) : null}
            className={renderAction ? 'with-action' : null}
          >
            {renderTitle ? renderTitle({ openState }) : title}
          </Box.Title>
        )}
        <CSSTransition
          classNames="showhide"
          nodeRef={nodeRef}
          timeout={{
            enter: 1000,
            exit: 500,
          }}
          unmountOnExit
          in={openState}
        >
          <Box.Content nodeRef={nodeRef}>
            {children}
          </Box.Content>
        </CSSTransition>
      </Box>
    </Box.CollapsibleWrapper>
  );
};

Box.Collapsible.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  rememberKey: PropTypes.string,
  open: PropTypes.bool,
  // renderOnlyAfterOpen: PropTypes.bool,
  renderTitle: PropTypes.func,
  renderAction: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
