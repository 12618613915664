import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const CmsLinkListCreateHintQuery = gql`
query CmsLinkListCreateHintQuery($name: String!) {
  cms_linklist_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        name
        internal_name
      }
    }
    key_suggestions
  }
}
`;

export const CmsLinkListCreateMutation = gql`
mutation CmsLinkListCreate(
  $name: String!,
  $key: String,
) {
  cms_linklist_create(
    name: $name,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
    key_suggestions
  }
}
`;

export const CmsLinkListReadQuery = gql`
fragment Link on Link {
  name
  url
  link_list {
    account
    key
    hashkey: _hashkey
  }

  cli_route
  cli_action

  alt
  desc
  image {
    account
    key
    hashkey: _hashkey
  }
  image_url

  ele_id
  ele_cls
}
query CmsLinkListReadQuery($resource: CmsLinkListInput!, $withHistory: Boolean) {
  cms_linklist_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name { name, internal_name }
      link {
        links {
          ...Link
          # https://github.com/graphql/graphql-spec/issues/91
          # level 2
          children {
            ...Link
            # level 3
            children {
              ...Link
              # level 4
              children {
                ...Link
                # level 5
                children {
                  ...Link
                }
              }
            }
          }
        }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const CmsLinkListUpdateMutation = gql`
mutation CmsLinkListUpdateMutation(
  $resource: CmsLinkListInput!,
  $name: CmsLinkListNameInput!,
  $link: CmsLinkListLinkInput!,
  $version: RecordStage
) {
  cms_linklist_update(
    resource: $resource,
    link: $link,
    name: $name,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const CmsLinkListDeleteMutation = gql`
mutation  CmsLinkListDeleteMutation(
  $resource: CmsLinkListInput!,
  $name: String!) {
  cms_linklist_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const CmsLinkListSearchQuery = gql`
query CmsLinkListSearchQuery($search: String!, $page: PagedRequestInput) {
  cms_linklist_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name {
        name
        internal_name
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const CmsLinkListNameQuery = gql`
query CmsLinkListNameQuery($resource: CmsLinkListInput!) {
  cms_linklist_name(resource: $resource) {
    name {
      name
      internal_name
    }
  }
}
`;
