import React, {
  useContext, lazy, Suspense,
} from 'react';
import styled from 'styled-components';
import { AvailableSports } from 'modal';
import { UserContext } from 'core/UserContext';

const CricketHomeDashboard = lazy(() => import('sports/cricket/Home'));
const EcomDashboard = lazy(() => import('ecom/Dashboard'));
const CmsDashboard = lazy(() => import('cms/Dashboard'));
const FootballHomeDashboard = lazy(() => import('sports/football/Home'));
const FantasyHomeDashboard = lazy(() => import('Fantasy/fantasyCreditEditor/Home'));

const DashboardWrapper = styled.div`
`;

export function HomePage() {
  const userContext = useContext(UserContext);

  let contentView = null;

  switch (userContext.sport) {
    case AvailableSports.Cricket.key:
      contentView = (<CricketHomeDashboard />);
      break;
    case AvailableSports.Football.key:
      contentView = (<FootballHomeDashboard />);
      break;
    case AvailableSports.FantasyEditor.key:
      contentView = (<FantasyHomeDashboard />);
      break;
    case AvailableSports.Ecom.key:
      contentView = (<EcomDashboard />);
      break;
    case AvailableSports.Cms.key:
      contentView = (<CmsDashboard />);
      break;
    default:
      break;
  }

  return (
    <DashboardWrapper>
      <Suspense>
        {contentView}
      </Suspense>
    </DashboardWrapper>
  );
}

HomePage.propTypes = {
};

export default HomePage;
