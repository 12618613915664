import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';
import { StructureFields } from 'cms/structure/query';

// export const CmsStructureContentCreateHintQuery = gql`
// query CmsStructureContentCreateHintQuery($name: String!) {
//   cms_structure_content_create_hint(name: $name) {
//     name
//     similar_items {
//       resource {
//         account
//         key
//         hashkey: _hashkey
//       }
//       name {
//         name
//       }
//     }
//     key_suggestions
//   }
// }
// `;

export const CmsStructureMenuQuery = gql`
query CmsStructureMenuQuery {
  cms_structure_content_menu {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      name { name, store_type }
    }
  }
}
`;

export const CmsStructureContentCreateMutation = gql`
mutation CmsStructureContentCreate(
  $structureKey: String!,
  $content: CmsStructureContentContentInput!,
  $internalName: String,
  $key: String,
) {
  cms_structure_content_create(
    structure_key: $structureKey,
    content: $content,
    internal_name: $internalName,
    key: $key,
  ) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const CmsStructureContentReadQuery = gql`
query CmsStructureContentReadQuery($resource: CmsStructureContentInput!, $withHistory: Boolean) {
  cms_structure_content_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        structure
        key
        hashkey: _hashkey
      }
      name {
        internal_name
      }
      content {
        data
        parsed_data
        archived
      }
    }
    structures {
      ${StructureFields}
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const CmsStructureContentUpdateMutation = gql`
mutation CmsStructureContentUpdateMutation(
  $resource: CmsStructureContentInput!,
  $content: CmsStructureContentContentInput!,
  $trackingName: String,
  $version: RecordStage,
) {
  cms_structure_content_update(
    resource: $resource,
    content: $content,
    tracking_name: $trackingName,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const CmsStructureContentDeleteMutation = gql`
mutation  CmsStructureContentDeleteMutation(
  $resource: CmsStructureContentInput!,
  $name: String!) {
  cms_structure_content_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const CmsStructureContentSearchQuery = gql`
query CmsStructureContentSearchQuery(
  $structureKey: String,
  $search: String!, 
  $page: PagedRequestInput,
) {
  cms_structure_content_search(structure_key: $structureKey, search: $search, page: $page) {
    items {
      resource {
        account
        structure
        key
        hashkey: _hashkey
      }
      name {
        internal_name
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const CmsStructureContentNameQuery = gql`
query CmsStructureContentNameQuery($resource: CmsStructureContentInput!) {
  cms_structure_content_name(resource: $resource) {
    name
  }
}
`;
