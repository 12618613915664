import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { GlobalPriceRuleImageView } from './ResourceImage';
import {
  GlobalPriceRuleSearchQuery,
  GlobalPriceRuleReadQuery,
  GlobalPriceRuleCreateMutation,
  GlobalPriceRuleUpdateMutation,
  GlobalPriceRuleNameQuery,
} from './query';

export const GlobalPriceRuleResource = Resource({
  resourceId: 'price',
  app: 'ecom',
  name: 'Global Price',
  keyVal: 'GlobalPriceRule',
  topicName: 'GlobalPriceRule',
  storeId: 'GlobalPriceRule',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Rule',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<GlobalPriceRuleImageView item={item} />),
  queries: {
    read: QueryAction({
      query: GlobalPriceRuleReadQuery,
      resourceNamePath: 'item.rule.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: GlobalPriceRuleSearchQuery, responsePath: 'ecom_price_search' }),
    search: QueryAction({
      query: GlobalPriceRuleSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'rule.name',
    }),
    name: QueryAction({ query: GlobalPriceRuleNameQuery, resourceNamePath: 'ecom_price_name.name' }),
  },
  mutations: {
    update: MutationAction({ mutation: GlobalPriceRuleUpdateMutation, cs: ['rule'] }),
    create: MutationAction({ mutation: GlobalPriceRuleCreateMutation }),
  },
});
