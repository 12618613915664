import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
  // RefField,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import { CmsLinkListImageView } from './ResourceImage';
import {
  CmsLinkListSearchQuery,
  CmsLinkListReadQuery,
  CmsLinkListCreateMutation,
  CmsLinkListUpdateMutation,
  CmsLinkListNameQuery,
} from './query';

export const MaxLinkLevel = 5;
const LinkRefFields = ['link_list', 'image'];
const LinkLocalFields = ['localFieldUrlType', 'localFieldImageType'];

export const DefaultUrlType = 'url';
export const UrlTypes = [
  { value: 'url', name: 'Url' },
  { value: 'linklist', name: 'Link List' },
  { value: 'cli', name: 'Client Route' },
  // { value: 'parent', name: 'Parent' },
];

export const DefaultImageType = 'url';
export const ImageTypes = [
  { value: 'image', name: 'Image Asset' },
  { value: 'url', name: 'Image Url' },
];

function contentAsObject(content) {
  const newContent = content.map((item) => {
    const newItem = { ...item };
    // LinkRefFields.forEach((field) => {
    //   if (newItem[field] && newItem[field].hashkey) {
    //     newItem[field] = newItem[field].hashkey;
    //   } else {
    //     newItem[field] = null;
    //   }
    // });

    if (newItem.cli_route || newItem.cli_action) {
      newItem.localFieldUrlType = 'cli';
    } else if (newItem.link_list && newItem.link_list.hashkey) {
      newItem.localFieldUrlType = 'linklist';
    } else {
      newItem.localFieldUrlType = 'url';
    }

    if (newItem.image_url) {
      newItem.localFieldImageType = 'url';
    } else {
      newItem.localFieldImageType = 'image';
    }

    newItem.children = newItem.children ? contentAsObject(newItem.children) : undefined;
    return newItem;
  });
  return arrayAsObject(newContent);
}

function contentAsArray(content) {
  let newContent = objectAsArray(content);
  newContent = newContent.map((item) => {
    const newItem = { ...item };
    LinkRefFields.forEach((field) => {
      if (newItem[field] && newItem[field].hashkey) {
        newItem[field] = { _hashkey: newItem[field].hashkey };
      } else {
        newItem[field] = null;
      }
    });

    if (newItem.localFieldUrlType === 'cli') {
      newItem.link_list = null;
      newItem.url = '';
    } else if (newItem.localFieldUrlType === 'linklist') {
      newItem.url = '';
      newItem.cli_route = '';
      newItem.cli_action = '';
    } else {
      newItem.link_list = null;
      newItem.cli_route = '';
      newItem.cli_action = '';
    }

    if (newItem.localFieldImageType === 'url') {
      newItem.image = null;
    } else {
      newItem.image_url = '';
    }

    LinkLocalFields.forEach((field) => {
      newItem[field] = undefined;
    });

    newItem.children = newItem.children ? contentAsArray(newItem.children) : undefined;
    return newItem;
  });
  return newContent;
}

export const CmsLinkListResource = Resource({
  resourceId: 'linklist',
  app: 'cms',
  name: 'LinkList',
  keyVal: 'CmsLinkList',
  topicName: 'CmsLinkList',
  storeId: 'CmsLinkList',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Name',
      shape: Yup.object().shape({
        name: StringFieldMinMax(2, 120),
      }),
    }),
    ColumnSet({
      name: 'Link',
      shape: Yup.object().shape({
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<CmsLinkListImageView item={item} />),
  queries: {
    read: QueryAction({
      query: CmsLinkListReadQuery,
      resourceNamePath: 'item.name.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: CmsLinkListSearchQuery, responsePath: 'cms_linklist_search' }),
    search: QueryAction({
      query: CmsLinkListSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.name',
    }),
    name: QueryAction({ query: CmsLinkListNameQuery, resourceNamePath: 'cms_linklist_name.name.name' }),
  },
  mutations: {
    update: MutationAction({
      mutation: CmsLinkListUpdateMutation,
      cs: ['name', 'link'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        if (!resp.link.links) {
          resp.link.links = [];
        }
        resp.link.links = contentAsObject(
          resp.link.links,
        );
        // console.log('prepareForEdit', JSON.stringify(resp.link.links, null, 2));
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.link.links = contentAsArray(resp.link.links);
        // console.log('prepareForSave', JSON.stringify(resp.link.links, null, 2));
        return resp;
      },
    }),
    create: MutationAction({ mutation: CmsLinkListCreateMutation }),
  },
});
